const statusDict = {
  user_order: "Заказ клиента на покупку",
  user_order_wait_confirm: "Ожидается подтверждение на покупку",
  user_order_confirmed: "Подтверждене покупки получено",
  waiting: "Ожидается получение",
  received_usa: "Получено на складе в США",
  processing: "На обработке в США",
  freezed_usa: "Остановлено в США",
  wait_payment: "Ожидается оплата",
  ready_to_send: "Готово к отправке",
  send_to_ru: "Отправлено в Россию",
  received_ru: "Получено на складе в России",
  moved_to_shipper: "Передано в доставку по России",
  delivered: "Доставлено",
  canceled: "Отменено",
};

export default statusDict;