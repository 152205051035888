<template>
  <div>
  <Header></Header>
    <Menu></Menu>
  <div id="page-content-wrapper">
    <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Панель управления</h2>

            <p>Общая информация по аккаунту</p>
            <div class="well">
              <form @submit.prevent="addBarcode">
                <div class="form-group">
                  <label for="ref_barcode">Номер отслеживания</label>
                  <input type="text" v-model="myData.ref_barcode" id="ref_barcode" class="form-control" placeholder="KH1234567890US">
                </div>
                <div class="form-group">
                  <label for="comment">Название отправления</label>
                  <input type="text" v-model="myData.comment" id="comment" class="form-control" placeholder="Джинсы">
                </div>
                <button type="submit" class="btn btn-success">Добавить</button>
              </form>

            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="panel">
                  <div class="panel-body">
                    <h3 class="title-hero">
                      Полученные треки
                    </h3>
                    <div class="example-box-wrapper">
                      <table class="table table-condensed">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Трек номер</th>
                          <th>Дата получения</th>
                          <th>Статус</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="barcode in receivedList" v-bind:key="barcode.id">
                          <td>{{barcode.id}}</td>
                          <td><router-link :to="{ name: 'mailbox_edit', params: { id: barcode.id } }" title="">{{barcode.ref_barcode}}</router-link>
                          <small v-if="barcode.comment">{{barcode.comment}}</small>
                          </td>
                          <td>{{barcode.received_date | msk_date}}</td>
                          <td>{{barcode.status}}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="panel">
                  <div class="panel-body">
                    <h3 class="title-hero">
                      Ожидаемые треки
                    </h3>
                    <div class="example-box-wrapper">
                      <table class="table table-condensed">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Трек номер</th>
                          <th>Дата добавления</th>
                          <th>Время в пути</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="barcode in waitingList" v-bind:key="barcode.id">
                          <td>{{barcode.id}}</td>
                          <td>
                            <router-link :to="{ name: 'mailbox_edit', params: { id: barcode.id } }" :title="barcode.comment">{{barcode.ref_barcode}}</router-link>
                          </td>
                          <td>{{barcode.add_date | msk_date}}</td>
                          <td>{{barcode.status}}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="panel">
                  <div class="panel-body">
                    <h3 class="title-hero">
                      Мой Адрес в США
                    </h3>
                    <table class="table">
                      <tbody>
                      <tr><td>Name:</td><td>Denis Ilinykh</td></tr>
                      <tr><td>Line 1:</td><td>700 Street Crossing</td></tr>
                      <tr><td>Line 2:</td><td>Ste 1199XXX</td></tr>
                      <tr><td>City:</td><td>Gotham</td></tr>
                      <tr><td>State:</td><td>Ohaio</td></tr>
                      <tr><td>ZIP:</td><td>12345-6788</td></tr>
                      <tr><td>Country:</td><td>USA</td></tr>
                      <tr><td>Phone:</td><td>(674) 1200890</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="well">
              <strong> {{ myData }} </strong>
              <button @click.prevent="CLEAR_USER">CLEAR_USER</button>

              <h2>user is client - {{ ROLE_IS('client') }} </h2>
              <h2>user is admin - {{ ROLE_IS('admin') }} </h2>
              <h2>user is staff - {{ ROLE_IS('staff') }} </h2>
              <h2>user is devil - {{ ROLE_IS('devil') }} </h2>
            </div>
          </div>



        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../components/SiteHeader'
import Menu from '../components/SiteMenu'
export default {
  name: 'index',
  components: {
    Header,
    Menu
  },
  data() {
    return {
      barcodes:[],
      myData: {

      }
    }
  },
  computed: {
    ...mapGetters(['ROLE_IS']),
    waitingList: function () {
      return this.barcodes.filter(function (item) {
        return item.status.match(/waiting/)
      })
    },
    receivedList: function () {
      return this.barcodes.filter(function (item) {
        return item.status.match(/received_usa/)
      })
    }
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
    addBarcode: function(){
      return Http.barcodeAdd(this.myData)
        .then(resp => {
          if(resp.data.status === "err"){
            alert(resp.data.errMessage);
          }else{
            $.jGrowl("Трек номер добавлен", {
              sticky: false,
              position: 'top-right',
              theme: 'bg-green'
            });
            this.barcodeList()
            this.myData.ref_barcode = ''
            this.myData.comment = ''
          }
        })
        .catch(() => console.error('Loading error'))
    },
    barcodeList: function () {
      return Http.barcodeList()
        .then(resp => {
          if(resp.data.status === "err"){
            alert(resp.data.errMessage);
          }else{
            this.barcodes = resp.data.barcodes
          }
        })
        .catch(() => console.error('Loading error'))
    }
  },
  created() {
    // this.barcodeList()
  }
}
</script>
