<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Оплаты</h2>
          </div>

          <div class="panel">
            <div class="panel-body">
              <div class="example-box-wrapper">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Дата</th>
                    <th>Посылка</th>
                    <th>Пользователь</th>
                    <th>Оплата</th>
                    <th>Списание</th>
                    <th>Комментарий</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="balance in myData.results" :key="balance.id">
                    <td>{{balance.id}}</td>
                    <td>{{balance.date| msk_date}}</td>
                    <td>
                      <router-link :to="{ name: 'parcels_edit', params: { id: balance.parcel } }" class="sfActive" title="Отправление" v-if="balance.parcel">
                        {{balance.parcel}}
                      </router-link>
                    </td>
                    <td>
                      <Client :id="balance.user" v-if="balance.user !== null"></Client>
                    </td>
                    <td>{{balance.credit}}</td>
                    <td>{{balance.debit}}</td>
                    <td>
                      {{balance.comment}}
                      <div class=" bs-label label-warning label" v-if="balance.for_items">
                        Оплата покупки товаров
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
import Client from "@/pages/main/admin/clients/Client";
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {
  name: 'balance',
  components: {
    Header,
    Menu,
    yandexMap,
    ymapMarker,
    Client
  },
  data() {
    return {
      myData: {},
    }
  },
  computed: {
  },
  methods: {
    getBalanceList: function () {
      Http.getBalanceList()
        .then(resp => {
          this.myData = resp.data;
        })
        .catch(() => console.error('Loading error'))
    },
  },
  created() {
    this.getBalanceList()
  }
}
</script>
