<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Паспорта Клиента #{{client.id}} {{client.login}}  {{client.last_name}} {{client.first_name}} {{client.mobile_phone}}</h2>
          </div>

          <div class="panel">
            <div class="panel-body">
              <client-links :client_id="client_id"></client-links>
              <h3 class="title-hero">
                Паспортные данные и ИНН
              </h3>
              <div class="example-box-wrapper">
                <router-link :to="{ name: 'client_passport_add', params: { client_id: client_id, passport_id: 0 } }" class="btn btn-success" title="Добавить паспорт клиенту">Добавить паспорт</router-link>
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>Фио</th>
                    <th>Серия/Номер</th>
                    <th>ИНН</th>
                    <th>Телефон</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="passport in Passports" v-bind:key="passport.client_id">
                    <td><router-link :to="{ name: 'client_passport_add', params: { client_id: passport.user, passport_id: passport.id } }" class="sfActive" title="Отправление">{{passport.last_name}} {{passport.first_name}}  {{passport.surname}}</router-link></td>
                    <td>
                      <span v-if="!passport.checked" class="font-red">
                        <i class="glyph-icon icon-warning"></i>
                      </span>
                      &nbsp; {{passport.serial}} {{passport.number}}</td>
                    <td>{{passport.inn}}</td>
                    <td>{{passport.mobile}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
import ClientLinks from "../../components/ClientLinks";
export default {
  name: 'passports',
  components: {
    Header,
    Menu,
    ClientLinks,
  },
  data() {
    return {
      Passports: [],
      client: {},
    }
  },
  props: {
    client_id: null
  },
  computed: {
    ...mapGetters(['ROLE_IS'])
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
    loadPassports: function (user_id) {
      Http.getAllPassportsData(user_id)
        .then(resp => {
          this.Passports = resp.data.results;
        })
        .catch(() => console.error('Loading error'))
    },
    getClient: function (client_id) {
      Http.getClient(client_id)
        .then(resp => {
          this.client = resp.data.results[0];
        })
        .catch(() => console.error('Loading error'))
    },
  },
  created() {
    this.loadPassports(this.client_id)
    this.getClient(this.client_id)
  }
}
</script>
