<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">

          <div id="page-title">
            <h2>Заказ на покупку</h2>

          </div>
          <div class="row">
            <div class="col-sm-12">
            <div class="panel">
              <div class="panel-body">
            <div v-if="order.user">
              <client-links :client_id="order.user"></client-links>
            </div>
              </div>
            </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
          <div class="panel">
            <div class="panel-body">

              <h3 class="title-hero">
                Основная информация по заказу
              </h3>
              <div class="example-box-wrapper">
                <div class="example-box-wrapper">
                  <form class="form-horizontal bordered-row" @submit.prevent="saveOrder">
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="ref_barcode">Штрихкод посылки</label>
                      <div class="col-sm-8">
                        <input type="text"  class="form-control" id="ref_barcode" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="comment">Комментарий</label>
                      <div class="col-sm-8">
                        <textarea  class="form-control" id="comment" >

                        </textarea>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label"></label>
                      <div class="col-sm-6">
                        <button class="btn btn-success" type="submit">Сохранить</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
            </div>
            <div class="col-sm-4">
              <div class="panel">
                <div class="panel-body">
                  <h3 class="title-hero">
                    Основная информация по заказу
                  </h3>
                  <div class="example-box-wrapper">
                    <a :href="order.item.url" target="_blank">
                      {{order.item.url}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
import ClientLinks from "../../components/ClientLinks";
import Client from "@/pages/main/admin/clients/Client";

export default {
  name: 'client_add',
  components: {
    Header,
    Menu,
    ClientLinks,
    Client,
  },
  data() {
    return {
      order: {

      },
    }
  },
  props: {
    order_id: null
  },
  computed: {
    ...mapGetters(['ROLE_IS'])
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
    getOrder: function () {
      Http.getOrder(this.order_id)
        .then(resp => {
          this.order = resp.data;
        })
        .catch(() => {
          $.jGrowl("Ошибка загрузки заказа", {
            sticky: false,
            position: 'top-right',
            theme: 'bg-red'
          });
        })
    },
    saveOrder: function () {
      this.client.username = this.client.email
      this.client.is_active = true
      Http.saveClient(this.client)
        .then(resp => {
            $.jGrowl("Сохранено", {
              sticky: false,
              position: 'top-right',
              theme: 'bg-green'
            });
            this.$router.push({ name: 'client_info', params: { client_id: resp.data.id } });
        })
        .catch((err) => {
          $.jGrowl(err.response.data.errMessage, {
            sticky: false,
            position: 'top-right',
            theme: 'bg-red'
          });
        })
    }
  },
  created() {
    this.getOrder(this.order_id)
  }
}
</script>
