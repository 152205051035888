<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Заказы в магазинах</h2>
          </div>
          <div class="panel">
            <div class="panel-body">
              <div class="btn-group" role="group" aria-label="...">
                <button
                  type="button"
                  class="btn"
                  :class="{
                    'btn-black-opacity-alt': filter_selected === statusKey,
                    'btn-default': filter_selected !== statusKey,
                  }"
                  @click="filterBy(statusKey)"
                  v-for="(status, statusKey) in buyStatusDict" :key="statusKey"
                >
                  {{status}}
                </button>
                <button
                  type="button"
                  class="btn"
                  :class="{
                    'btn-black-opacity-alt': filter_selected === null,
                    'btn-default': filter_selected !== null,
                  }"
                  @click="filterBy(null)"
                >
                  ALL
                </button>
              </div>
              <hr/>
              <form @submit.prevent="searchBarcode">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="search_text" placeholder="Search for...">
                      <span class="input-group-btn">
                        <button class="btn btn-default" type="submit">Go!</button>
                      </span>
                    </div><!-- /input-group -->
                  </div><!-- /.col-lg-6 -->
                </div><!-- /.row -->
              </form>
              <hr/>
              <div class="example-box-wrapper">
                <div v-if="parcelsLoading">Загрузка посылок...</div>
                <table class="table table-condensed table-hover" v-else>
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Дата</th>
                    <th>Товар</th>
                    <th>Посылка</th>
                    <th>Клиент</th>
                    <th>Статус</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="order in getParcels" v-bind:key="order.id">
                    <td><router-link :to="{ name: 'order_info', params: { order_id: order.id } }" class="sfActive" title="Отправление">{{order.id}}</router-link></td>
                    <td><router-link :to="{ name: 'order_info', params: { order_id: order.id } }" class="sfActive" title="Отправление">{{order.add_date | msk_date}}</router-link></td>
                    <td>{{order.item}}</td>
                    <td>{{order.parcel}}</td>
                    <td><Client :id="order.user" v-if="order.user !== null"></Client></td>
                    <td><div class="btn-group dropup">
                      <router-link  tag="button" :to="{ name: 'order_info', params: { order_id: order.id } }" class="btn btn-info" style="max-width: 180px;overflow: hidden;text-overflow: ellipsis;">{{buyStatusDict[order.status]}}</router-link>
                      <button type="button" class="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                        <span class="caret"></span>
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      <ul class="dropdown-menu float-right dropup" role="menu">
                        <li v-for="(status, statusKey) in buyStatusDict" :key="statusKey">
                          <a href="#" @click.prevent="setStatus(order.id,statusKey)">
                            {{status}}
                          </a>
                        </li>

                        <li class="divider"></li>
                        <li><a href="#">Подготовить к отправке</a></li>
                      </ul>
                    </div></td>
                  </tr>
                  </tbody>
                </table>
                <div v-if="!parcelsLoading && getParcels.length === 0">
                  Список посылок пуст
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
import Client from "@/pages/main/admin/clients/Client";
import buyStatusDict from "@/services/buyStatusDict";

export default {
  name: 'orders',
  components: {
    Header,
    Menu,
    Client
  },
  data() {
    return {
      filter_selected: null,
      parcelsLoading: false,
      parcels: {
        results: [],
      },
      search_text: null,
      buyStatusDict,
    }
  },
  computed: {
    ...mapGetters(['ROLE_IS']),
    getParcels(){
      return this.parcels.results;
    }
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
    filterBy: function (selected){
      this.$router.push({name: 'orders', query: { filter: selected }});
      this.filter_selected = selected;
      this.loadOrdersList();
    },
    searchBarcode: function() {
      Http.searchParcelsList(this.search_text)
        .then(resp => {
          this.parcels = resp.data;
        })
        .catch(() => console.error('Loading error'))
    },
    loadOrdersList: function () {
      this.parcelsLoading = true;
      Http.getOrderList(this.filter_selected)
        .then(resp => {
          // Если выбран фильтр по статусу, и возвращенные посылки не соответствуют выбранному статусу то не обновляем
          // if( this.filter_selected && resp.data.results.length && this.filter_selected !== resp.data.results[0].status ) return;
          this.parcels = resp.data;
          this.parcelsLoading = false;
        })
        .catch((err) => alert(err.message))
    },
    setStatus: function (id, status) {
      Http.setParcelStatus(id, {status: status}).then(resp => {
        // this.loadParcelsList()

        const timestamp = new Date().valueOf()

        let Index = this.parcels.results.findIndex(function (item) {
          return parseInt(item.id) === parseInt(id)
        })

        if(this.parcels.results[Index]) {
          this.parcels.results[Index]["status"] = status
          this.parcels.results[Index]["key"] = `${id}${timestamp}`
        }

      })
      .catch(() => console.error('Loading error'))
    }
  },
  created() {
    if( this.$route.query.filter === undefined ){
      this.filter_selected = null;
    } else {
      this.filter_selected = this.$route.query.filter;
    }
    this.loadOrdersList()
  }
}
</script>
<style lang="scss" scoped>
  ._parcel_links {
      white-space: normal;
      max-width: 160px;
      overflow: hidden;
  }
  ._parcel_link{
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
