<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Паспорт: {{Passport.last_name}} {{Passport.first_name}} {{Passport.surname}}</h2>
          </div>

          <div class="panel">

            <div class="panel-body">
              <client-links :client_id="client_id"></client-links>

              <h3 class="title-hero">
                Данные паспорта и ИНН
              </h3>

              <div class="example-box-wrapper">
                <div class="example-box-wrapper">
                  <form class="form-horizontal bordered-row">
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="serial">Серия</label>
                      <div class="col-sm-6">
                        <input type="number" max="4" min="4" v-model="Passport.serial" class="form-control" id="serial" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="number">Номер</label>
                      <div class="col-sm-6">
                        <input type="number" max="6" min="6" v-model="Passport.number" class="form-control" id="number" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="last_name">Фамилия</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.last_name" class="form-control" id="last_name" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="first_name">Имя</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.first_name" class="form-control" id="first_name" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="surname">Отчество</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.surname" class="form-control" id="surname" placeholder="">
                      </div>
                    </div>
                    <div class="form-group" hidden>
                      <label class="col-sm-2 control-label" for="gender">Пол</label>
                      <div class="col-sm-6">
                        <select class="form-control" v-model="Passport.gender" id="gender">
                          <option value="male">Мужской</option>
                          <option value="female">Женский</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="birth_date">Дата рождения</label>
                      <div class="col-sm-6">
                        <input type="date" v-model="Passport.birth_date" class="form-control" id="birth_date" placeholder="">
                      </div>
                    </div>
                    <div class="form-group" hidden>
                      <label class="col-sm-2 control-label" for="birth_place">Место рождения</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.birth_place" class="form-control" id="birth_place" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="issuer">Кем выдан</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.issuer" class="form-control" id="issuer" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="issuer_date">Дата выдачи</label>
                      <div class="col-sm-6">
                        <input type="date" v-model="Passport.issuer_date" class="form-control" id="issuer_date" placeholder="">
                      </div>
                    </div>
                    <div class="form-group" hidden>
                      <label class="col-sm-2 control-label" for="issuer_code">Код подразделения</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.issuer_code" class="form-control" id="issuer_code" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="inn">ИНН</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.inn" class="form-control" id="inn" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="inn">Телефон</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.mobile" class="form-control" id="mobile" placeholder="79636678990">
                      </div>
                    </div>
                  <div>
                    <h2 class="page-header">Прописка</h2>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="district">Район/ Область</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.district" class="form-control" id="district" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="city">Город</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.city" class="form-control" id="city" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="post_code">Индекс</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.post_code" class="form-control" id="post_code" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="street">Улица</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.street" class="form-control" id="street" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="building">Дом</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.building" class="form-control" id="building" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="flat">Квартира</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="Passport.flat" class="form-control" id="flat" placeholder="">
                      </div>
                    </div>
                  </div>

                    <div>
                      <div class="form-group">
                        <label class="col-sm-2 control-label" for="flat">Паспорт проверен</label>
                        <div class="checkbox">
                          <label>
                            <input type="checkbox" value="" v-model="Passport.checked">
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-sm-2 control-label"></label>
                      <div class="col-sm-6">
                        <button class="btn btn-success" @click.prevent="savePassport" type="button">Сохранить</button>
                      </div>
                    </div>


                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
import ClientLinks from "../../components/ClientLinks";

export default {
  name: 'passports',
  components: {
    Header,
    Menu,
    ClientLinks
  },
  data() {
    return {
      Passport: {},
    }
  },
  props: {
    client_id: null,
    passport_id: null,
  },
  computed: {
    ...mapGetters(['ROLE_IS'])
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
    savePassport: function(){
      this.Passport.user = this.client_id
      return Http.saveClientPassportData(this.Passport, this.client_id)
        .then(resp => {
          if(resp.data.status === "err"){
            alert(resp.data.errMessage);
          }else{
            $.jGrowl("Сохранено", {
              sticky: false,
              position: 'top-right',
              theme: 'bg-green'
            });
            this.$router.push({ name: 'client_passports', params: { client_id: this.client_id } });
          }
        })
        .catch(() => console.error('Loading error'))
    },
    loadPassport: function (user_id) {
      Http.getClientPassport(user_id)
        .then(resp => {
          this.Passport = resp.data;
        })
        .catch(() => console.error('Loading error'))
    }
  },
  created() {
    if(this.passport_id > 0) {
      this.loadPassport(this.passport_id)
    }
  }
}
</script>
