<template>
  <div id="page-header" class="bg-gradient-9">

    <div id="mobile-navigation">
      <button id="nav-toggle" class="collapsed" data-toggle="collapse" data-target="#page-sidebar"><span></span></button>
      <a href="index.html" class="logo-content-small" title="MonarchUI"></a>
    </div>
    <div id="header-logo" class="logo-bg">
      <a href="index.html" class="logo-content-big" title="MonarchUI">
        Monarch <i>UI</i>
        <span>The perfect solution for user interfaces</span>
      </a>
      <a href="index.html" class="logo-content-small" title="MonarchUI">
        Monarch <i>UI</i>
        <span>The perfect solution for user interfaces</span>
      </a>
      <a id="close-sidebar" href="#" title="Close sidebar">
        <i class="glyph-icon icon-angle-left"></i>
      </a>
    </div>
    <!-- #header-nav-left -->

    <div id="header-nav-right">
      <a href="#" class="hdr-btn popover-button" title="" data-placement="bottom" data-id="#popover-search" data-original-title="Search">
        <i class="glyph-icon icon-search"></i>
      </a>
      <div class="hide" id="popover-search">
        <div class="pad5A box-md">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Search terms here ...">
            <span class="input-group-btn">
                <a class="btn btn-primary" href="#">Search</a>
            </span>
          </div>
        </div>
      </div>
      <a class="header-btn" href="http://support.boxbo.ru/otrs/index.pl" target="_blank" style="color: #fff;width: 100px;text-transform: uppercase;text-decoration: none;">
        Support
      </a>
      <a class="header-btn" id="logout-btn" href="#"  @click.prevent="CLEAR_USER" title="Выход">
        <i class="glyph-icon icon-linecons-lock"></i>
      </a>

    </div><!-- #header-nav-right -->
  </div>

</template>

<script>
  import {mapMutations} from "vuex";

  export default {
    name: 'siteheader',
    data() {
      return {
        myData: ''
      }
    },
    methods: {
      ...mapMutations(['CLEAR_USER'])
    },
  }
</script>
