import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import './assets/bootstrap/css/bootstrap.css'
// import * as Sentry from "@sentry/browser";
// import { Vue as VueIntegration } from "@sentry/integrations";
// import { Integrations } from "@sentry/tracing";
import vmodal from 'vue-js-modal'

// <!-- HELPERS -->

import './assets/helpers/animate.css'
import './assets/helpers/backgrounds.css'
import './assets/helpers/boilerplate.css'
import './assets/helpers/border-radius.css'
import './assets/helpers/grid.css'
import './assets/helpers/page-transitions.css'
import './assets/helpers/spacing.css'
import './assets/helpers/typography.css'
import './assets/helpers/utils.css'
import './assets/helpers/colors.css'

// <!-- ELEMENTS -->

import './assets/elements/badges.css'
import './assets/elements/buttons.css'
import './assets/elements/content-box.css'
import './assets/elements/dashboard-box.css'
import './assets/elements/forms.css'
import './assets/elements/images.css'
import './assets/elements/info-box.css'
import './assets/elements/invoice.css'
import './assets/elements/loading-indicators.css'
import './assets/elements/menus.css'
import './assets/elements/panel-box.css'
import './assets/elements/response-messages.css'
import './assets/elements/responsive-tables.css'
import './assets/elements/ribbon.css'
import './assets/elements/social-box.css'
import './assets/elements/tables.css'
import './assets/elements/tile-box.css'
import './assets/elements/timeline.css'



  // <!-- ICONS -->

import './assets/icons/fontawesome/fontawesome.css'
import './assets/icons/linecons/linecons.css'
import './assets/icons/spinnericon/spinnericon.css'


  // <!-- WIDGETS -->

import './assets/widgets/accordion-ui/accordion.css'
import './assets/widgets/calendar/calendar.css'
import './assets/widgets/carousel/carousel.css'

import './assets/widgets/charts/justgage/justgage.css'
import './assets/widgets/charts/morris/morris.css'
import './assets/widgets/charts/piegage/piegage.css'
import './assets/widgets/charts/xcharts/xcharts.css'

import './assets/widgets/chosen/chosen.css'
import './assets/widgets/colorpicker/colorpicker.css'
import './assets/widgets/datatable/datatable.css'
import './assets/widgets/datepicker/datepicker.css'
import './assets/widgets/datepicker-ui/datepicker.css'
import './assets/widgets/daterangepicker/daterangepicker.css'
import './assets/widgets/dialog/dialog.css'
import './assets/widgets/dropdown/dropdown.css'
import './assets/widgets/dropzone/dropzone.css'
import './assets/widgets/file-input/fileinput.css'
import './assets/widgets/input-switch/inputswitch.css'
import './assets/widgets/input-switch/inputswitch-alt.css'
import './assets/widgets/ionrangeslider/ionrangeslider.css'
import './assets/widgets/jcrop/jcrop.css'
import './assets/widgets/jgrowl-notifications/jgrowl.css'
import './assets/widgets/loading-bar/loadingbar.css'
import './assets/widgets/maps/vector-maps/vectormaps.css'
import './assets/widgets/markdown/markdown.css'
import './assets/widgets/modal/modal.css'
import './assets/widgets/multi-select/multiselect.css'
// import './assets/widgets/multi-upload/fileupload.css'
import './assets/widgets/nestable/nestable.css'
import './assets/widgets/noty-notifications/noty.css'
import './assets/widgets/popover/popover.css'
// import './assets/widgets/pretty-photo/prettyphoto.css'
import './assets/widgets/progressbar/progressbar.css'
import './assets/widgets/range-slider/rangeslider.css'
import './assets/widgets/slidebars/slidebars.css'
import './assets/widgets/slider-ui/slider.css'
import './assets/widgets/summernote-wysiwyg/summernote-wysiwyg.css'
import './assets/widgets/tabs-ui/tabs.css'
import './assets/widgets/theme-switcher/themeswitcher.css'
import './assets/widgets/timepicker/timepicker.css'
import './assets/widgets/tocify/tocify.css'
import './assets/widgets/tooltip/tooltip.css'
import './assets/widgets/touchspin/touchspin.css'
import './assets/widgets/uniform/uniform.css'
import './assets/widgets/wizard/wizard.css'
// import './assets/widgets/xeditable/xeditable.css'

  // <!-- SNIPPETS -->

import './assets/snippets/chat.css'
import './assets/snippets/files-box.css'
import './assets/snippets/login-box.css'
import './assets/snippets/notification-box.css'
import './assets/snippets/progress-box.css'
import './assets/snippets/todo.css'
import './assets/snippets/user-profile.css'
import './assets/snippets/mobile-navigation.css'

  // <!-- APPLICATIONS -->

import './assets/applications/mailbox.css'

  // <!-- Admin theme -->

import './assets/themes/admin/layout.css'
import './assets/themes/admin/color-schemes/default.css'

  // <!-- Components theme -->

import './assets/themes/components/default.css'
import './assets/themes/components/border-radius.css'

  // <!-- Admin responsive -->

import './assets/helpers/responsive-elements.css'
import './assets/helpers/admin-responsive.css'

Vue.use(VueAxios, axios);
Vue.use(vmodal);
//
// Sentry.init({
//   dsn: "https://c0f515ee4c7044a8a3e44c94937183c2@sentry.greyhard.ru/11",
//   integrations: [
//     new VueIntegration({
//       Vue,
//       tracing: true,
//     }),
//     new Integrations.BrowserTracing(),
//   ],
//   tracesSampleRate: 1,
// });

Vue.config.productionTip = false;

Vue.filter('usa_date', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY hh:mm')
  }
});

Vue.filter('msk_date', function(utcDate) {
  if (utcDate) {
    return new Date(utcDate).toLocaleString()
    // return moment(String(value)).format('DD/MM/YYYY hh:mm')
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
