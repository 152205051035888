<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">

          <div id="page-title">
            <h2 v-if="client.id">Редактирование Клиента</h2>
            <h2 v-else>Добавить клиента</h2>
          </div>

          <div class="panel">
            <div class="panel-body">
              <div v-if="client.id" class="example-box-wrapper">
                <client-links :client_id="client_id"></client-links>
              </div>
              <h3 class="title-hero">
                Основная информация
              </h3>
              <div class="example-box-wrapper">
                <div class="example-box-wrapper">
                  <form class="form-horizontal bordered-row" @submit.prevent="saveClient">
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="last_name">Фамилия</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="client.last_name" class="form-control" id="last_name" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="first_name">Имя</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="client.first_name" class="form-control" id="first_name" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="first_name">Email</label>
                      <div class="col-sm-6">
                        <input type="email" v-model="client.email" class="form-control" id="email" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="mobile_phone">Мобильный телефон</label>
                      <div class="col-sm-6">
                        <input type="text" v-model="client.mobile_phone" class="form-control" id="mobile_phone" placeholder="" required>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="password">Пароль</label>
                      <div class="col-sm-6">
                        <input type="password" v-model="client.password" class="form-control" id="password" placeholder="">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label"></label>
                      <div class="col-sm-6">
                        <button class="btn btn-success" type="submit">Сохранить</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
import ClientLinks from "../../components/ClientLinks";

export default {
  name: 'client_add',
  components: {
    Header,
    Menu,
    ClientLinks
  },
  data() {
    return {
      client: {

      },
    }
  },
  props: {
    client_id: null
  },
  computed: {
    ...mapGetters(['ROLE_IS'])
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
    getClient: function (client_id) {
      if(client_id) {
        Http.getClient(client_id)
          .then(resp => {
            this.client = resp.data.results[0];
          })
          .catch(() => {
            $.jGrowl("Ошибка загрузки клиента", {
              sticky: false,
              position: 'top-right',
              theme: 'bg-red'
            });
          })
      }
    },
    saveClient: function () {
      this.client.username = this.client.email
      this.client.is_active = true
      Http.saveClient(this.client)
        .then(resp => {
            $.jGrowl("Сохранено", {
              sticky: false,
              position: 'top-right',
              theme: 'bg-green'
            });
            this.$router.push({ name: 'client_info', params: { client_id: resp.data.id } });
        })
        .catch((err) => {
          $.jGrowl(err.response.data.errMessage, {
            sticky: false,
            position: 'top-right',
            theme: 'bg-red'
          });
        })
    }
  },
  created() {
    this.getClient(this.client_id)
  }
}
</script>
