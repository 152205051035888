<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Клиенты</h2>
          </div>

          <div class="panel">
            <div class="panel-body">
              <form @submit.prevent="searchUsers">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="search_text" placeholder="Search for...">
                      <span class="input-group-btn">
                        <button class="btn btn-default" type="submit">Go!</button>
                      </span>
                    </div><!-- /input-group -->
                  </div><!-- /.col-lg-6 -->
                </div><!-- /.row -->
              </form>
            </div>
          </div>

          <div class="panel">
            <div class="panel-body">
              <h3 class="title-hero">
                Клиенты
              </h3>
              <div class="example-box-wrapper">
                <router-link :to="{ name: 'client_add' }" class="btn btn-success" title="Добавить посылку">Добавить клиента</router-link>
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>Дата Регистрации</th>
                    <th>Логин</th>
                    <th>Имя</th>
                    <th>Фамилия</th>
                    <th>Мобильный</th>
                    <th>Email</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="client in myData.results" :key="client.id">
                    <td>{{client.date_joined| msk_date}}</td>
                    <td><router-link :to="{ name: 'client_info', params: { client_id: client.id } }" class="sfActive" title="Клиент">{{client.username}}</router-link></td>
                    <td>{{client.last_name}}</td>
                    <td>{{client.first_name}}</td>
                    <td>{{client.mobile_phone}}</td>
                    <td>
                      <span v-if="!client.is_email_active" class="font-red">
                        <i class="glyph-icon icon-warning"></i>
                      </span>
                      &nbsp;<router-link :to="{ name: 'client_info', params: { client_id: client.id } }" class="sfActive" title="Клиент">{{client.email}}</router-link>
                    </td>
                    <td><button type="button" @click="disableUser(client)" class="btn btn-sm btn-danger">Disable</button> </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'

export default {
  name: 'clients',
  components: {
    Header,
    Menu,
  },
  data() {
    return {
      myData: {},
      search_text: null,
    }
  },
  computed: {
    ...mapGetters(['ROLE_IS'])
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
    searchUsers: function() {
      Http.getClientsTypeahead(this.search_text)
        .then(resp => {
          this.myData = resp.data;
        })
        .catch(() => console.error('Loading error'))
    },
    disableUser: function (client){
      client.is_active = false
      Http.disableClient(client)
        .then(resp => {
          this.getClientsList()
        })
        .catch(() => console.error('Loading error'))
    },
    getClientsList: function () {
      Http.getClientsList()
        .then(resp => {
          this.myData = resp.data;
        })
        .catch(() => console.error('Loading error'))
    },
  },
  created() {
    this.getClientsList()
  }
}
</script>
