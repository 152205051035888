<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Документы AWB</h2>
          </div>

          <div class="panel">
            <div class="panel-body">
              <h3 class="title-hero">
                Документы AWB
              </h3>
              <div class="example-box-wrapper">
                <table class="table">
                  <thead>
                  <tr>
                    <th></th>
                    <th>ManifestID</th>
                    <th>ManifestNumber</th>
                    <th>ManifestDate</th>
                    <th>ManifestWeigth</th>
                    <th>ManifestVolume</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="awb in AwbList" :key="awb.ManifestID">
                    <td><input type="checkbox"></td>
                    <td>{{awb.ManifestID}}</td>
                    <td>{{awb.ManifestNumber}}</td>
                    <td>{{awb.ManifestDate}}</td>
                    <td>{{awb.ManifestWeigth}}</td>
                    <td>{{awb.ManifestVolume}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
export default {
  name: 'awb',
  components: {
    Header,
    Menu
  },
  data() {
    return {
      AwbList: [],
    }
  },
  computed: {
    ...mapGetters(['ROLE_IS'])
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
    loadAWB: function () {
      Http.getAWB()
        .then(resp => {
          // console.log( resp)
          this.AwbList = resp.data.results;
        })
        .catch(() => console.error('Loading error'))
    },
    createAWB: function (){
      alert("createAWB")
    }
  },
  created() {
    this.loadAWB()
  }
}
</script>
