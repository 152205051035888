<template>
  <div>
    <router-link :to="{ name: 'client_info', params: { client_id: id } }" :class="textClass" title="Клиент">
      #{{ user.id }} {{ user.first_name }} {{ user.last_name }}
    </router-link>
  </div>

</template>

<script>

import { mapMutations } from 'vuex';
import Http from "@/services/Http";

export default {
  strict: true,
  name: 'Client',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    user: {
      code: null,
      company: null,
      id: null,
      is_email_active: false,
    }
  }),
  computed: {
    textClass: function (){
      return {
        'red_text': this.user.is_email_active !== true,
        'sfActive': true,
      }
    },
  },
  methods: {
    ...mapMutations(["setUser"]),

    getData: function () {

      if (typeof this.$store.getters.getUsers[this.id] !== 'undefined'){
        this.user = this.$store.getters.getUsers[this.id];
        return;
      }

      Http.getClient(this.id)
        .then(resp => {
          if(resp.data.count === 1){
            this.user = resp.data.results[0]
            this.setUser({
              id: this.id,
              user: resp.data.results[0]
            })
          }
        })
          .catch((error) => {
            alert("Loading Error")
            this.captureException(error)
          })
    },
  },
  created() {
      this.getData();
  }

}
</script>

<style scoped>
.red_text {
  color: red;
}
</style>
