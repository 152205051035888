<template>

  <div class="example-box-wrapper">

    <div v-if="client_id">
    <router-link
      :to="{
        name: 'client_info',
        params: { client_id: client_id },
      }"
      class="btn btn-success"
      title="Все посылки"
    >Посылки</router-link
    >
    &nbsp;
    <router-link
      :to="{
        name: 'parcels_add',
        params: { client_id: client_id },
      }"
      class="btn btn-success"
      title="Добавить посылку"
      >Добавить посылку</router-link
    >
    &nbsp;
    <router-link
      :to="{
        name: 'client_edit',
        params: { client_id: client_id },
      }"
      class="btn btn-success"
      title="Редактирование клиента"
      >Редактирование клиента</router-link
    >
    &nbsp;
    <router-link
      :to="{
        name: 'client_passports',
        params: { client_id: client_id },
      }"
      class="btn btn-success"
      title="Паспорта"
      >Паспорта</router-link
    >
    &nbsp;
    <router-link
      :to="{
        name: 'client_adresses',
        params: { client_id: client_id },
      }"
      class="btn btn-success"
      title="Адреса доставки"
      >Адреса доставки</router-link
    >
    &nbsp;
    <router-link
      :to="{
        name: 'client_balance',
        params: { client_id: client_id },
      }"
      class="btn btn-success"
      title="Баланс клиента"
      >Баланс</router-link
    >
    &nbsp;
    <router-link
      :to="{
        name: 'client_bonuses',
        params: { client_id: client_id },
      }"
      class="btn btn-success"
      title="Бонусы клиента"
      >Бонусы</router-link
    >
    &nbsp;
    <button
      class="btn btn-danger"
      title="Логин за юзера"
      @click.prevent="loginAsUser"
      >Логин за юзера</button
    >
      </div>
    <div v-else>
      <router-link
        :to="{
        name: 'parcels_new',
      }"
        class="btn btn-success"
        title="Разбор склада"
      >Разбор склада</router-link
      >
    </div>
  </div>
</template>
<script>
import Http from '../../services/Http'
export default {
  props: ['client_id'],
  methods: {
    async loginAsUser(){
      if (!window.confirm("Авторизоваться за пользователя?")) return;
      const resp = await Http.getUserToken(this.client_id);
      window.open(`https://lk.boxbo.ru/external/?token=${resp.data.token}`, "_self");
    }
  }
}
</script>
