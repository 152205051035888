<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Настройки</h2>
          </div>

          <div class="panel">
            <div class="panel-body">
              <ul class="nav nav-tabs mrg25B">
                <li class="active"><a href="#">Настройки тарифа</a></li>
                <!-- <li><a href="#">Profile</a></li>
                <li><a href="#">Messages</a></li> -->
              </ul>
              <div class="row">
                <div class="col-md-6">
                  <h2 class="title-hero">Значения</h2>
                  <table class="table table-hover">
                    <tbody>
                      <tr v-for="(item, itemInd) in calcConfig" :key="item.id">
                        <td>{{item.comment}} ({{item.key}})</td>
                        <td>
                          <div class="input-group">
                            <input type="text" class="form-control" v-model="item.value">
                            <span class="input-group-btn">
                              <button class="btn btn-primary" type="button" @click="saveParam(itemInd)">
                                <i class="glyph-icon icon-edit"></i>
                              </button>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-6">
                  <h2 class="title-hero">Калькулятор превью настроек</h2>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
import Http from '../../../services/Http'
export default {
  name: 'settings',
  components: {
    Header,
    Menu
  },
  data() {
    return {
      calcConfig: []
    }
  },
  computed: {
    ...mapGetters(['ROLE_IS'])
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
    async saveParam(ind){
      const { id, value } = this.calcConfig[ind];
      await Http.setCalcConfig( id, value );
      $.jGrowl("Значение сохранено", {
          sticky: false,
          position: 'top-right',
          theme: 'bg-green'
        });
      this.getCalcConfig();
    },
    async getCalcConfig(){
      const {data: {results}} = await Http.getCalcConfig();
      this.calcConfig = results.sort((a,b)=> (a.id > b.id ? 1 : -1));
    }
  },
  created() {
    this.getCalcConfig();
  }
}
</script>
