<template>
  <div>
    <div class="_wrapper">
      <h2 class="_h2">Изменить владельца посылки</h2>
      <div class="_input">
        <VueBootstrapTypeahead
          :data="users"
          v-model="userSearch"
          :serializer="s => `[${s.id}] ${s.first_name} ${s.last_name} - ${s.email} - ${s.mobile_phone}`"
          placeholder="Имя, почта или телефон..."
          @hit="selectedUser = $event"
          inputClass="width-100"
          ></VueBootstrapTypeahead>
      </div>
      <div class="_footer">
        <button class="btn btn-primary" @click.prevent="saveParcel">Сохранить</button>
      </div>
    </div>
  </div>
</template>
<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Http from '@/services/Http'
import _ from 'lodash'

export default {
  components: {
    VueBootstrapTypeahead
  },
  data() {
    return {
      userSearch: "",
      users: [],
      selectedUser: null
    }
  },
  props: ['parcel'],
  watch: {
    userSearch: _.debounce(function(addr) { this.getUsers(addr) }, 500),
    selectedUser(val){
      this.parcel.user = val.id;
    }
  },

  methods: {
    async getUsers(query) {
      const suggestionsRes = await Http.getClientsTypeahead(query);
      this.users = suggestionsRes.data.results;
    },
    saveParcel: function(){
      Http.addParcel(this.parcel)
        .then(resp => {
          this.$emit('userSelected');
        })
        .catch((err) => {
          console.log(err);
          $.jGrowl("Ошибка сохранения", {
            sticky: false,
            position: 'top-right',
            theme: 'bg-red'
          });
        })
    },
  },
}
</script>

<style scoped>

._wrapper{
  padding: 24px
}
._h2{
  margin-bottom: 24px;
}
._input{
  margin-bottom: 16px;
}
._footer{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
</style>
