<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Добавить адрес доставки</h2>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="panel">
                <div class="panel-body">
                  <h3 class="title-hero">
                    Заполните все поля
                  </h3>
                  <div class="example-box-wrapper">
                    <form class="form-horizontal bordered-row">
                      <div class="form-group">
                        <label class="col-sm-3 control-label" for="comment">Название / комментарий</label>
                        <div class="col-sm-6">
                          <input type="text" v-model="Address.comment" class="form-control" id="comment" placeholder="Мой домашний адрес">
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label" for="country_code">Страна</label>
                        <div class="col-sm-6">
                          <select class="form-control" v-model="Address.country_code" id="country_code">
                            <option value="RU" selected="selected">Россия</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label" for="post_code">Индекс</label>
                        <div class="col-sm-6">
                          <input type="number" maxlength="6" v-model="Address.post_code" class="form-control" id="post_code" placeholder="">
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label" for="district">Район</label>
                        <div class="col-sm-6">
                          <input type="text" v-model="Address.district" class="form-control" id="district" placeholder="">
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label" for="city">Город</label>
                        <div class="col-sm-6">
                          <input type="text" v-model="Address.city" class="form-control" id="city" placeholder="">
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label" for="street">Улица</label>
                        <div class="col-sm-6">
                          <input type="text" v-model="Address.street" class="form-control" id="street" placeholder="">
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label" for="building">Номер дома</label>
                        <div class="col-sm-6">
                          <input type="text" v-model="Address.building" class="form-control" id="building" placeholder="">
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label" for="flat">Квартира / Оффис</label>
                        <div class="col-sm-6">
                          <input type="text" v-model="Address.flat" class="form-control" id="flat" placeholder="">
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="col-sm-6 col-sm-offset-3">
                          <label>
                            <input type="checkbox" v-model="Address.to_boxbo_office" id="to_boxbo_office"> Последняя миля доставка в офис Boxbo
                          </label>

                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"></label>
                        <div class="col-sm-6">

                          <button class="btn btn-success" @click.prevent="saveAddress" type="button">Сохранить</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
export default {
  name: 'client_adresses_add',
  components: {
    Header,
    Menu
  },
  data() {
      return {
          Address: {
            "user": this.client_id
          }
      }
  },
  computed: {
      ...mapGetters(['ROLE_IS'])
  },
  props: {
    client_id: null,
    id: null
  },
  methods: {
      ...mapMutations(['CLEAR_USER']),
    saveAddress: function () {
       Http.saveClientAddres(this.Address)
        .then(resp => {
          if(resp.data.status === "err"){
            $.jGrowl(resp.data.errMessage, {
              sticky: false,
              position: 'top-right',
              theme: 'bg-red'
            });
          }else{
            $.jGrowl("Сохранено", {
              sticky: false,
              position: 'top-right',
              theme: 'bg-green'
            });
            this.$router.push({ name: 'client_adresses', params: { client_id: this.client_id } })
          }
        })
        .catch(() => console.error('Loading error'))
    },
    loadAddress: function () {
      return Http.getClientAddress(this.id)
        .then(resp => {
            this.Address = resp.data
        })
        .catch(() => console.error('Loading error'))
    }
  },
  created() {
    this.loadAddress()
  }
}
</script>
