<template>
  <div class="center-vertical">
    <div class="center-content row">
      <form action="" @submit.prevent="goLogin" id="login-validation" class="col-md-4 col-sm-5 col-xs-11 col-lg-3 center-margin" method="">
        <h3 class="text-center pad25B font-gray text-transform-upr font-size-23">Вход</h3>
        <div id="login-form" class="content-box bg-default">
          <div class="content-box-wrapper pad20A">
            <img class="mrg25B center-margin radius-all-100 display-block" src="../../assets/image-resources/gravatar.jpg" alt="">
            <div class="form-group">
              <div class="input-group">
                            <span class="input-group-addon addon-inside bg-gray">
                                <i class="glyph-icon icon-envelope-o"></i>
                            </span>
                <input type="email" class="form-control" v-model.trim="username" id="exampleInputEmail1" placeholder="Логин" required>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                            <span class="input-group-addon addon-inside bg-gray">
                                <i class="glyph-icon icon-unlock-alt"></i>
                            </span>
                <input type="password" class="form-control" v-model.trim="password" id="exampleInputPassword1" placeholder="Пароль" required>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-block btn-primary">Войти</button>
            </div>
            <div class="row">

            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations } from 'vuex'

export default {
  name: 'login',
  data () {
    return {
      username: '',
      password: '',
      rememeber_me: '',
    }
  },
  methods: {
    ...mapMutations(['SET_USER']),
    goLogin () {
      Http.auth({ username: this.username, password: this.password })
        .then(resp => {
          this.SET_USER(resp.data);
          this.$router.push({ name: 'parcels' })
        })
        .catch(() => {
          // console.error('Здесь надо повесить алерт с ошибкой')
          $.jGrowl("Ошибка авторизации, попробуйте восстановить пароль", {
            sticky: false,
            position: 'top-right',
            theme: 'bg-red'
          });
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
