<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Добавить посылку</h2>
          </div>


          <div class="row">
            <div class="col-sm-9">
              <div class="panel">


                <div class="panel-body">
                  <client-links :client_id="Client.id"></client-links>
                  <hr/>
                  <div class="example-box-wrapper">
                    <div class="_parcel_info">
                      <div class="_parcel_info_badge bs-label label-warning" v-if="parcel.boxbo_buy">
                        <i class="glyph-icon icon-life-ring"></i>
                        Boxbo-buy
                      </div>
                      <div class="_parcel_info_badge bs-label label-info" v-if="parcel.delivery === 'euro'">
                        Доставка евро
                      </div>
                      <div class="_parcel_info_badge bs-label label-success" v-if="parcel.delivery === 'boxbo'">
                        Стандартаная доставка
                      </div>
                      Посылка добавлена {{parcel.add_date | msk_date}}
                    </div>
                    <form
                      class="form-horizontal bordered-row"
                      @submit.prevent="saveParcel"
                    >
                      <div class="form-group" v-if="photos.length" :class="{'_disabled': photosBlocked}">
                        <label class="col-sm-3 control-label">Фотографии</label>
                        <div class="col-sm-9">
                          <div class="row">
                            <div
                              class="col-sm-3"
                              v-for="photo in photos"
                              :key="photo.id"
                            >
                              <div class="_photo" :class="{'--active': photo.user === parcel.user}">
                                <button class="_photo_remove btn-danger" @click.prevent="removePhoto(photo)">✖</button>
                                <a :href="photo.image" target="_blank" class="image-box">
                                  <img :src="photo.image" class="img-full" />
                                </a>
                                <a href="#" class="_photo-checkbox" @click.prevent="togglePhotoUser(photo)">
                                  <i class="glyph-icon icon-check"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group" v-if="videos.length">
                        <label class="col-sm-3 control-label">Видео</label>
                        <div class="col-sm-9">
                          <a
                            :href="video.video"
                            v-for="video in videos"
                            :key="video.id"
                          >
                            {{video.video}}
                          </a>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Клиент</label>
                        <div class="col-sm-9">
                          <router-link :to="{ name: 'client_info', params: { client_id: Client.id } }" v-if="Client.last_name || Client.first_name || Client.emailP">
                            {{ Client.last_name }} {{ Client.first_name }},
                            {{ Client.email }}
                          </router-link>
                          <span v-else> Не указан </span>
                          <a
                            href="#"
                            class="btn btn-info change-client-btn"
                            @click.prevent="$modal.show('change-client')"
                            >Сменить клиента</a
                          >
                          <modal name="change-client" height="auto">
                            <SelectUser :parcel="this.parcel" v-on:userSelected="userChanged"></SelectUser>
                          </modal>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Паспорт получателя</label
                        >
                        <div class="col-sm-6">
                          <select class="form-control" v-model="parcel.passport">
                            <option :value="null">Не выбрано</option>
                            <option
                              v-for="Passport in Passports"
                              :value="Passport.id"
                              :key="Passport.id"
                            >
                              {{ Passport.last_name }} {{ Passport.first_name }} [{{
                                Passport.serial
                              }}, {{ Passport.number }}]
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- v-if="parcel.status === 'waiting'" -->
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Тип доставки</label>
                        <div class="col-sm-6">
                          <select class="form-control" v-model="parcel.delivery">
                            <option value="boxbo">Быстрая</option>
                            <option value="euro">Медленная</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Постамат</label
                        >
                        <div class="col-sm-6">
                          ID {{parcel.locker}},
                          <span v-if="lockerInfo.pointData">
                            {{lockerInfo.pointData.Region}}
                            {{lockerInfo.pointData.City}}
                            {{lockerInfo.pointData.Address}},
                            LockerOwner: {{lockerInfo.pointData.LockerOwner}},
                            LockerContractor: {{lockerInfo.pointData.LockerContractor}}
                          </span>
                          <div class="change-locker-btn btn btn-info" @click.prevent="$modal.show('change-locker')">
                            Сменить постамат
                          </div>

                          <modal name="change-locker" height="auto" :scrollable="true">
                            <ChangeLocker v-on:valueChanged="parcel.locker = $event" :selectedLocker="lockerInfo"/>
                            <div class="modal-footer">
                              <div type="button" class="btn btn-default" @click.prevent="$modal.hide('change-locker')">Отменить</div>
                              <div type="button" class="btn btn-primary" @click="saveLockerChange">Сохранить выбранный</div>
                            </div>
                          </modal>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Трек номер</label>
                        <div class="col-sm-6">
                          <input
                            type="text"
                            v-model="parcel.ref_barcode"
                            class="form-control"
                            placeholder="Трек номер если есть"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Статус</label>
                        <div class="col-sm-6">
                          <select class="form-control" v-model="parcel.status">
                            <option
                              :value="statusKey"
                              v-for="(status, statusKey) in statusDict"
                              :key="statusKey"
                            >
                              {{ status }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Размеры (мм)</label>
                        <div class="col-sm-2">
                          <input
                            type="text"
                            v-model="parcel.depth"
                            class="form-control"
                            placeholder="Длина, см"
                          />
                        </div>
                        <div class="col-sm-2">
                          <input
                            type="text"
                            v-model="parcel.width"
                            class="form-control"
                            placeholder="Ширина, см"
                          />
                        </div>
                        <div class="col-sm-2">
                          <input
                            type="text"
                            v-model="parcel.length"
                            class="form-control"
                            placeholder="Высота, см"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Вес (грамм)</label>
                        <div class="col-sm-6">
                          <input
                            type="text"
                            v-model="parcel.weight"
                            class="form-control"
                            placeholder="Вес в граммах"
                          />
                        </div>
                      </div>
                      <div class="form-group" v-for="(item, index) in parcel.items" :key="index">
                        <label class="col-sm-3 control-label">
                          Товар #{{ index + 1 }}<br />
                          <small
                            ><a href="#" @click.prevent="removeItem(index, item)"
                              >Удалить</a
                            ></small
                          >
                        </label>
                        <div class="col-sm-3">
                          <input
                            type="text"
                            v-model="item.description"
                            class="form-control"
                            placeholder="Название"
                          />
                        </div>
                        <div class="col-sm-2">
                          <input
                            type="url"
                            v-model="item.url"
                            class="form-control"
                            placeholder="Ссылка"
                          />
                        </div>
                        <div class="col-sm-1">
                          <input
                            type="text"
                            v-model="item.unit_value"
                            @focus="$event.target.select()"
                            class="form-control"
                            placeholder="Цена"
                          />
                        </div>
                        <div class="col-sm-1">
                          <input
                            type="number"
                            v-model="item.quantity"
                            @focus="$event.target.select()"
                            class="form-control"
                            placeholder="Количество"
                          />
                        </div>
                        <div class="col-sm-1">
                          <input
                            type="number"
                            v-model="item.unit_weight"
                            @focus="$event.target.select()"
                            class="form-control"
                            placeholder="Вес"
                          />
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="col-sm-3 control-label"></label>
                        <div class="col-sm-6">
                          <a href="#" @click.prevent="addItem">Добавить товар</a>
                        </div>
                      </div>
                      <div class="form-group" v-if="parcel.label_image">
                        <label class="col-sm-3 control-label" for="invoice"
                          >Шиппинг лейбл</label
                        >
                        <div class="col-sm-6">
                          <img :src="labelImageUrl" class="img-responsive" />
                        </div>
                      </div>
                      <div class="form-group" v-if="parcel.invoice_file">
                        <label class="col-sm-3 control-label" for="invoice"></label>
                        <div class="col-sm-6">
                          <a
                            :href="invoiceImageUrl"
                            class="btn btn-info"
                            target="_blank"
                            ><i class="glyph-icon icon-file-o"></i> Прикрепленный
                            инвойс</a
                          >
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label" for="invoice"
                          >Инвойс</label
                        >
                        <div class="col-sm-6">
                          <input
                            type="file"
                            name="invoice"
                            ref="file"
                            @change="handleFilesUpload"
                            class="form-control"
                            id="invoice"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"></label>
                        <div class="col-sm-6">
                          <button class="btn btn-success btn-lg" type="submit">
                            Сохранить</button
                          >&nbsp;
                          <button v-if="parcel.status === 'user_order_confirmed'" @click="makeOrder()" class="btn btn-info btn-lg" type="button">
                            Создать покупку</button
                          >&nbsp;
<!--                          <button-->
<!--                            v-if="parcel.id && parcel.status === 'received_usa' && parcel.delivery !== 'euro'"-->
<!--                            type="button"-->
<!--                            @click="createParcelHWB"-->
<!--                            class="btn btn-warning "-->
<!--                          >-->
<!--                            Создать HWB</button-->
<!--                          >&nbsp;-->
<!--                          <button-->
<!--                            v-if="parcel.id"-->
<!--                            type="button"-->
<!--                            class="btn btn-danger"-->
<!--                            disabled-->
<!--                          >-->
<!--                            Удалить Посылку</button-->
<!--                          >&nbsp;-->
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <ParcelDetailPrice type="parcel" :id="id" />
              <hr/>
              <h3 class="title-hero">Опции товара</h3>
              <div class="_options" :class="{'--loading': optionsLoading}">
                <div class="_options-item" v-for="item in optionsList" :key="item.id" :class="{'--active': isOptionEnabled(item.id)}" @click="toggleOption(item.id)">
                  <div class="_options-item-ico text-primary">
                    <i class="glyph-icon icon-check"></i>
                  </div>
                  <div class="_options-item-text">
                    {{item.name}}
                  </div>
                  <div class="_options-item-price">
                    {{item.price}}
                    <span v-if="item.type === 'absolute'">$</span>
                    <span v-else>%</span>
                  </div>
                </div>
                <div class="_options-spinner">
                  <div class="glyph-icon remove-border demo-icon tooltip-button icon-spin-1 icon-spin" title="" data-original-title="icon-spin-1"></div>
                </div>
              </div>
              <hr/>
              <h3 class="title-hero">История статусов</h3>
              <div class="_status-log">
                <div class="_status-log_item" v-for="item in statusLog" :key="item.id">
                  <div class="_status-log_item_text">[{{item.date | msk_date}}] {{statusDict[item.status]}}</div>
                </div>
              </div>
              <hr/>
              <Comments type="parcel" :id="id" />

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from "@/services/Http";
import { mapMutations, mapGetters } from "vuex";
import Header from "../../components/SiteHeader";
import Menu from "../../components/SiteMenu";
import SelectUser from "../../components/SelectUser.vue";
import _ from "underscore";
import statusDict from "@/services/statusDict.js";
import Comments from '../../components/Comments.vue';
import ParcelDetailPrice from '../../components/ParcelDetailPrice.vue';

import ChangeLocker from '../../components/ChangeLocker.vue';
import ClientLinks from "../../components/ClientLinks";

export default {
  name: "parcels-edit",
  components: {
    Header,
    Menu,
    SelectUser,
    Comments,
    ChangeLocker,
    ClientLinks,
    ParcelDetailPrice
  },
  data() {
    return {
      parcel: {
        items: [
          {
            description: "",
            unit_value: 0,
            unit_weight: 0,
            quantity: 0,
            url: ""
          },
        ],
        address: null,
      },
      lockerInfo: {},
      Client: {},
      Addresses: [],
      Passports: [],
      file: "",
      statusDict,
      photosBlocked: false,
      photos: [],
      videos: [],
      optionsList: [],
      optionsLoading: false,
      statusLog: []
    };
  },
  props: {
    id: null,
    client_id: null
  },
  watch: {
    parcel(newVal, oldVal ){
      if(oldVal !== newVal){
        if(!this.parcel.locker)  return;
        Http.getCityPointInfo(this.parcel.locker).then(({data})=>{
          this.lockerInfo = data;
        })
      }
    }
  },
  computed: {
    ...mapGetters(["ROLE_IS"]),
    invoiceImageUrl: function () {
      return `${this.parcel.invoice_file}`;
    },
    labelImageUrl: function () {
      return `${process.env.VUE_APP_ROOT_API}${this.parcel.label_image}`;
    },
  },
  methods: {
    ...mapMutations(["CLEAR_USER"]),
    userChanged(){
      this.loadParcel(this.id);
      this.$modal.hide('change-client');
    },
    makeOrder(){
      Http.makeOrder(this.id).then(() => {
        this.$router.push({name: 'orders', query: { filter: null }});
      });
    },
    fetchData: function(){
      if( this.id ){
        // Редактирование посылки
        this.loadParcel(this.id);
      } else if( this.client_id ){
        // Создание посылки
        this.getClient(this.client_id);
        this.loadAdresses(this.client_id);
        this.loadPassports(this.client_id);

        this.parcel.user = this.client_id;
      }
    },
    saveItem: function (parcel_id) {
      this.parcel.items.forEach((it) => {
        it.parcel = parcel_id;
        Http.addParcelItem(it).then((resp) => {
          console.log(resp);
        });
      });
    },
    saveParcel: function () {
      if (this.parcel.length === "") {
        this.parcel.length = 0;
      }

      if (this.parcel.depth === "") {
        this.parcel.depth = 0;
      }

      if (this.parcel.width === "") {
        this.parcel.width = 0;
      }

      if (this.parcel.ref_barcode === "") {
        this.parcel.ref_barcode = null;
      }


      delete this.parcel.invoice_file;

      Http.addParcel(this.parcel)
        .then((resp) => {
          Http.setParcelStatus(this.parcel.id, { status: this.parcel.status })
            .then(() => {
              this.saveItem(resp.data.id);
              if (resp.data.status === "exist") {
                alert("Barcode Exists");
              } else {
                if (this.file !== "") {
                  this.saveParcelInvoice(resp.data.parcel_id);
                } else {
                  $.jGrowl("Сохранено", {
                    sticky: false,
                    position: "top-right",
                    theme: "bg-green",
                  });
                  this.fetchData();
                }
              }
            })
            .catch(() => {
              $.jGrowl("Ошибка сохранения Статуса", {
                sticky: false,
                position: "top-right",
                theme: "bg-red",
              });
            });
        })
        .catch(() => {
          $.jGrowl("Ошибка сохранения", {
            sticky: false,
            position: "top-right",
            theme: "bg-red",
          });
        });
    },
    saveParcelInvoice: function () {
      let formData = new FormData();
      formData.append("invoice_image", this.file);
      formData.append("parcel_id", this.parcel.id);

      Http.addParcelInvoice(formData)
        .then((resp) => {
          if (resp.data.status === "ok") {
            $.jGrowl("Сохранено", {
              sticky: false,
              position: "top-right",
              theme: "bg-green",
            });
            this.fetchData();
          } else {
            alert("File upload error");
          }
        })
        .catch((err) => console.error(err));
    },
    loadParcel: function (parcel_id) {
      Http.getParcel(parcel_id)
        .then((resp) => {
          this.parcel = resp.data;

          if(this.parcel.user !== null){
            this.getClient(this.parcel.user);
            this.loadAdresses(this.parcel.user);
            this.loadPassports(this.parcel.user);
          }

          this.loadImages();
          this.loadVideos();

          this.optionsLoading = false;
        })
        .catch((err) => console.error(err));
    },
    createParcelHWB: function () {
      Http.createHWB(this.parcel.id)
        .then((resp) => {
          if (resp.data.status === "ok") {
            this.parcel.status = "ready_to_send";
          } else {
            alert("Create HWB load error");
          }
        })
        .catch(() => console.error("Create HWB load error"));
    },
    addItem: function () {
      this.parcel.items.push({
        description: "",
        unit_value: 0,
        unit_weight: 0,
        quantity: 0,
        url: ""
      });
    },
    removeItem: function (index, item) {
      if (confirm("Убрать строку?")) {
        this.parcel.items.splice(index, 1);
      }

      if (item) {
        Http.removeParcelItem(item)
          .then((_) => {})
          .catch((err) => console.error(err));
      }

      if (this.parcel.items.length === 0) {
        this.parcel.items.push({
          description: "",
          unit_value: 0,
          unit_weight: 0,
          quantity: 0,
          url: ""
        });
      }
    },
    handleFilesUpload() {
      this.file = this.$refs.file.files[0];
    },
    getClient: function (user) {
      Http.getClient(user)
        .then((resp) => {
          this.Client = resp.data.results[0];
        })
        .catch((err) => console.error(err));
    },
    loadAdresses: function (user) {
      Http.getClientAddressList(user)
        .then((resp) => {
          this.Addresses = resp.data.results;
        })
        .catch((err) => console.error(err));
    },
    loadPassports: function (user) {
      Http.getAllPassportsData(user)
        .then((resp) => {
          this.Passports = resp.data.results;
        })
        .catch((err) => console.error(err));
    },
    loadImages: function () {
      Http.getParcelImages(this.id)
        .then((resp) => {
          this.photos = resp.data.results;
        })
        .catch((err) => console.error(err));
    },
    removePhoto: function(el){
      if (window.confirm(`Удалить фото с id ${el.id}`)) {
        Http.removeParcelImage(el.id)
        .then(()=>{
          this.fetchData();
        })
        .catch(err=>{
          alert(`Ошибка ${err.message}`);
        })
      }
    },
    async togglePhotoUser(el){
      this.photosBlocked = true;
      const userToBeSet = el.user === this.parcel.user ? null : this.parcel.user;
      try {
        await Http.setParcelImageAccess(el.id, {'user': userToBeSet});
      } catch (error) {
        alert(error.message);
      }
      this.photosBlocked = false;
      this.loadImages();
    },
    loadVideos: function () {
      Http.getParcelVideos(this.id)
        .then((resp) => {
          this.videos = resp.data.results;
        })
        .catch((err) => console.error(err));
    },
    saveLockerChange(){
      this.saveParcel();
      this.$modal.hide('change-locker');
    },
    async fetchOptionsList(){
      const {data: {results}} = await Http.getOptions();
      this.optionsList = results;
    },
    async fetchSatusLog(){
      const {data: {results}} = await Http.getParcelStatusLog(this.id);
      this.statusLog = results;
    },
    isOptionEnabled(id){
      const foundInSelected = this.parcel.option.filter(el=>{
        return el.option === id;
      });
      return foundInSelected.length > 0;
    },
    async toggleOption(optionId){
      this.optionsLoading = true;
      try{
        if( this.isOptionEnabled(optionId) ){
          const optionGlobalId = this.parcel.option.find(el=>{return el.option === optionId}).id;
          await Http.removeOption(optionGlobalId);
        } else {
          await Http.setOption(this.Client.id, this.parcel.id, optionId);
        }
      } catch(err){
        alert(err.message);
      }
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
    this.fetchOptionsList();
    this.fetchSatusLog();
  },
};
</script>

<style scoped>
._disabled{
  opacity: 0.4;
  pointer-events: none;
}
._parcel_info{
  margin-bottom: 16px;
  font-size: 18px;
}
._parcel_info_badge{
  margin-right: 8px;
  font-size: 12px;
}
.change-client-btn {
  margin-left: 8px;
}
.change-locker-btn {
  display: block;
  margin-top: 8px;
}
._options{
  margin-bottom: 48px;
  position: relative;
}
._options.--loading{

}
._options.--loading ._options-spinner{
  opacity: 1;
  visibility: visible;
}
._options-item{
  margin-bottom: 12px;
  display: flex;
  opacity: 0.5;
  cursor: pointer;
  justify-content: space-between;
  line-height: 1.2;
}
._options-item:hover{
  color: #3498db;
}
._options-item.--active{
  opacity: 1;
}
._options-item.--active .glyph-icon{
  opacity: 1;
}
._options-item .glyph-icon{
  opacity: 0;
}
._options-item-ico{
  flex-shrink: 0;
  width: 24px;
}
._options-item-text{
  margin-left: 0;
  margin-right: auto;
}
._options-item-price{
  white-space: nowrap;
  padding-left: 8px;
  font-weight: bold;
}
._options-spinner{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,.9);
  transition: all .4s;
  opacity: 0;
  visibility: hidden;
}
._photo{
  position: relative;
}
._photo.--active{
  box-shadow: 0 0 0 4px #29b765;
}
._photo.--active ._photo-checkbox{
  background-color: #29b765;
  color: #fff;
}
._photo.--active ._photo-checkbox i{
  opacity: 1;
}
._photo_remove{
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 2;
  border: 0;
}
._photo-checkbox{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
    border-radius: 100px;
}
._photo-checkbox i{
  opacity: 0.3;
}
._status-log{

}
._status-log_item{
  display: flex;
  margin-bottom: 12px;
  line-height: 1.1;
}
._status-log_item_date{
  opacity: 0.8;
  width: 120px;
}
._status-log_item_text{

}
</style>
