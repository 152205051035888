import axios from 'axios'
import store from '@/store'

axios.interceptors.response.use(undefined, function (err) {
  return new Promise(function (resolve, reject) {
    if (err.response && err.response.status === 401) store.commit('CLEAR_USER');
    throw err
  })
});

const API_URL = `${process.env.VUE_APP_ROOT_API}/api`;

export default {

  getHeaders () {
    return {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token') ,
        // 'Content-Type': 'multipart/form-data'
      }
    }
  },
  getHeadersFormData () {
    return {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token') ,
        'Content-Type': 'multipart/form-data'
      }
    }
  },

  get (url) {
    return axios.get(url, this.getHeaders())
  },

  post (url, data) {
    return axios.post(url, data, this.getHeaders())
  },

  postFormData (url, data) {
    return axios.post(url, data, this.getHeadersFormData())
  },

  delete (url, data) {
    return axios.delete(url, { headers: this.getHeaders().headers, data  })
  },

  put (url, data) {
    return axios.put(url, data, this.getHeaders())
  },

  auth (user) {
    return axios.post(`${API_URL}/token/`, user)
  },

  refreshToken (token) {
    return axios.post(`${API_URL}/token/refresh/`, { token })
  },

  getPassportData(){
    return this.get(`${API_URL}/passport/`)
  },

  getAllPassportsData(user_id){
    return this.get(`${API_URL}/passport?user=${user_id}`)
  },

  savePassportData(Passport){
    return this.post(`${API_URL}/passport/`, Passport  )
  },

  itemAdd(itemData){
    return this.post(`${API_URL}/shipping/item/add/`, itemData  )
  },

  barcodeAdd(itemData){
    return this.post(`${API_URL}/shipping/barcode/`, itemData  )
  },

  barcodeList(){
    return this.get(`${API_URL}/shipping/barcode/`)
  },

  getNewParcelsList(){
    return this.get(`${API_URL}/shipping/unknown`)
  },

  getParcelsList(status){
    if(status){
      return this.get(`${API_URL}/shipping/parcels?status=${status}`)
    }
    return this.get(`${API_URL}/shipping/parcels`)
  },

  getParcelsListQuery(query){
    const queryArr = [];
    for (let [key, value] of Object.entries(query)) {
      if( value !== null) queryArr.push(`${key}=${value}`)
    }
    return this.get(`${API_URL}/shipping/parcels?${queryArr.join('&')}`)
  },

  searchParcelsList(ref_barcode){
    return this.get(`${API_URL}/shipping/parcels?search=${ref_barcode}`)
  },

  getParcel(parcel_id){
    return this.get(`${API_URL}/shipping/parcel/${parcel_id}`)
  },
  getParcelStatusLog(parcel_id){
    return this.get(`${API_URL}/shipping/statuslog?parcel=${parcel_id}`)
  },
  setParcelStatus(parcel_id, parcelData){
    return this.post(`${API_URL}/shipping/parcels/setstatus/`,{
      parcel_id: parcel_id,
      status: parcelData.status,
    });
    // return this.put(`${API_URL}/shipping/parcel/${parcel_id}`, parcelData )
  },


  getOrderList(status){
    if(status){
      return this.get(`${API_URL}/boxbuy/orders?status=${status}`)
    }
    return this.get(`${API_URL}/boxbuy/orders`)
  },

  getOrder(order_id){
      return this.get(`${API_URL}/boxbuy/orders/${order_id}`)
  },

  searchOrdersList(ref_barcode){
    return this.get(`${API_URL}/boxbuy/orders/search?search=${ref_barcode}`)
  },

  makeOrder(parcel_id){
    return this.get(`${API_URL}/boxbuy/create/${parcel_id}`)
  },

  setOrderStatus(order_id, orderData){
    return this.post(`${API_URL}/boxbuy/orders/setstatus`,{
      order_id: order_id,
      status: orderData.status,
    });
  },

  addParcel(parcelData){
    if(parcelData.id){
      return this.put(`${API_URL}/shipping/parcel/${parcelData.id}`, parcelData )
    }
    return this.post(`${API_URL}/shipping/parcel`, parcelData )
  },

  addParcelItem(itemData){
    if(itemData.id){
      return this.put(`${API_URL}/shipping/item/${itemData.id}`, itemData )
    }
    return this.post(`${API_URL}/shipping/item`, itemData )
  },

  removeParcelItem(itemData){
    return this.delete(`${API_URL}/shipping/item/${itemData.id}`)
  },

  addParcelInvoice(FileData){
    return this.postFormData(`${API_URL}/shipping/parcels/addinvoice/`, FileData)
  },

  getAddressList(){
    return this.get(`${API_URL}/address/`)
  },

  saveAddressList(AddressData){
    return this.post(`${API_URL}/address/`, AddressData )
  },

  deleteAddressList(AddressId){
    return this.delete(`${API_URL}/address/`, { "address_id": AddressId })
  },

  getCityPoints(city){
    return this.get(`${API_URL}/shipping/citypoints/?city=${city}`)
  },

  getCityPointInfo(id){
    return this.get(`${API_URL}/shipping/citypoints/${id}`)
  },

  getClientsList(){
    return this.get(`${API_URL}/clients`)
  },

  disableClient(client){
    return this.put(`${API_URL}/clients/${client.id}`, client )
  },

  getClient(client_id){
    return this.get(`${API_URL}/clients?id=${client_id}`)
  },

  getClientsTypeahead(query){
    return this.get(`${API_URL}/clients?search=${query}`)
  },

  getClientsSearch(query){
    return this.get(`${API_URL}/clients/search/?query=${query}`)
  },

  saveClient(userData){
    if(userData.id){
      return this.put(`${API_URL}/clients/${userData.id}`, userData )
    }
    return this.post(`${API_URL}/clients`, userData  )
  },

  addMoney(client_id, moneyData){
    return this.post(`${API_URL}/pay/addmoney/${client_id}`, moneyData  )
  },

  getClientPassport(client_id){
    return this.get(`${API_URL}/passport/${client_id}`)
  },

  saveClientPassportData(Passport){
    if(Passport.id) {
      return this.put(`${API_URL}/passport/${Passport.id}`, Passport)
    }else{
      return this.post(`${API_URL}/passport`, Passport)
    }
  },

  getClientAddressList(client_id){
    return this.get(`${API_URL}/address?user=${client_id}`)
  },

  deleteClientAddressList(address){
    return this.put(`${API_URL}/address/${address.id}`, address)
  },

  getClientAddress(address_id ){
    return this.get(`${API_URL}/address/${address_id}`)
  },

  saveClientAddres(AddressData){
    if(AddressData.id){
      return this.put(`${API_URL}/address/${AddressData.id}`, AddressData )
    }
    return this.post(`${API_URL}/address`, AddressData )
  },

  createHWB(parcel_id){
    return this.get(`${API_URL}/docs/hwb/create/${parcel_id}/`)
  },

  updateHWB(HWBNumber){
    return this.get(`${API_URL}/docs/hwb/update/${HWBNumber}/`)
  },

  getHWB(){
    return this.get(`${API_URL}/docs/hwb/`)
  },

  getAWB(){
    return this.get(`${API_URL}/docs/awb/`)
  },

  createAWB(awbData){
    return this.post(`${API_URL}/docs/awb/create/`, awbData)
  },

  getBalanceList(query){
    const queryArr = [];
    if(query){
      for (let [key, value] of Object.entries(query)) {
        if( value !== null) queryArr.push(`${key}=${value}`)
      }
    }
    return this.get(`${API_URL}/balance/?${queryArr.join('&')}`)
  },
  getBonusesList(query){
    const queryArr = [];
    if(query){
      for (let [key, value] of Object.entries(query)) {
        if( value !== null) queryArr.push(`${key}=${value}`)
      }
    }
    return this.get(`${API_URL}/bonuses/?${queryArr.join('&')}`)
  },
  getParcelImages(parcel_id){
    return this.get(`${API_URL}/shipping/images?parcel=${parcel_id}`)
  },
  removeParcelImage(image_id){
    return this.delete(`${API_URL}/shipping/images/${image_id}`)
  },
  setParcelImageAccess(image_id, payload){
    return this.put(`${API_URL}/shipping/images/access/${image_id}`, payload);
  },
  getParcelVideos(parcel_id){
    return this.get(`${API_URL}/shipping/videos?parcel=${parcel_id}`)
  },
  setComment(type, id, comment){
    const data = {
      "key": `${type}_${id}`,
      "comment": comment
    }
    return this.post(`${API_URL}/docs/comments/`, data)
  },
  getComments(type, id){
    return this.get(`${API_URL}/docs/comments/?key=${type}_${id}`)
  },
  setCalcConfig(id, value){
    const data = {
      "value": value
    }
    return this.put(`${API_URL}/calc_config/${id}`, data)
  },
  getCalcConfig(){
    return this.get(`${API_URL}/calc_config`)
  },
  getShippingCitiesList(){
    return this.get(`${API_URL}/shipping/cities`)
  },
  getOptions() {
    return this.get(`${API_URL}/shipping/options`);
  },
  setOption(userId, parcelId, optionId) {
    return this.post(`${API_URL}/shipping/poptions`, {
      user: userId,
      parcel: parcelId,
      option: optionId,
    })
  },
  removeOption(optionGlobalId) {
    return this.delete(`${API_URL}/shipping/poptions/${optionGlobalId}`)
  },
  getParcelPriceDetail(parcel_id) {
    return this.get(`${API_URL}/pay/parcel/detail/${parcel_id}`);
  },
  getUserToken(user_id){
    return this.get(`${API_URL}/relogin/${user_id}`)
  }
}
