<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Баланс</h2>
          </div>

          <div class="panel">
            <div class="panel-body">
              <client-links :client_id="client_id"></client-links>
              <div class="example-box-wrapper">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Дата</th>
                    <th>Посылка</th>
                    <th>Пользователь</th>
                    <th>Кредит</th>
                    <th>Дебет</th>
                    <th>Комментарий</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="balance in myData.results" :key="balance.id">
                    <td>{{balance.id}}</td>
                    <td>{{balance.date}}</td>
                    <td>
                      <router-link :to="{ name: 'parcels_edit', params: { id: balance.parcel } }" class="sfActive" title="Отправление" v-if="balance.parcel">
                        {{balance.parcel}}
                      </router-link>
                    </td>
                    <td>
                      <router-link :to="{ name: 'client_info', params: { client_id: balance.user } }" class="sfActive" title="Клиент">
                        {{balance.user}}
                      </router-link>
                    </td>
                    <td>{{balance.credit}}</td>
                    <td>{{balance.debit}}</td>
                    <td>{{balance.comment}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
import ClientLinks from "../../components/ClientLinks";

export default {
  name: 'bonuses-client',
  components: {
    Header,
    Menu,
    ClientLinks,
  },
  data() {
    return {
      myData: {},
    }
  },
  props: {
    client_id: null,
  },
  computed: {
  },
  methods: {
    getBonusesList: function () {
      Http.getBonusesList({user: this.client_id})
        .then(resp => {
          this.myData = resp.data;
        })
        .catch(() => console.error('Loading error'))
    },
  },
  created() {
    this.getBonusesList()
  }
}
</script>
