<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">

          <div id="page-title">
            <h2>Адреса доставки</h2>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="panel">
                <div class="panel-body">
                  <client-links :client_id="client_id"></client-links>
                  <h3 class="title-hero">
                    Адреса для доставки посылок
                  </h3>
                  <div class="example-box-wrapper">
                    <router-link :to="{ name: 'client_adresses_add', params: { client_id: client_id, id: 0 } }" class="btn btn-success" title="Добавить адрес доставки">Добавить адрес доставки</router-link>&nbsp;
                    <router-link :to="{ name: 'client_adresses_add_locker', params: { client_id: client_id, id: 0 } }" class="btn btn-success" title="Добавить пункт самовывоза">Добавить пункт самовывоза</router-link>
                    <table class="table table-hover">
                      <thead>
                      <tr>
                        <th>Индекс</th>
                        <th>Город</th>
                        <th>Улица</th>
                        <th>Дом</th>
                        <th>Комментарий</th>
                        <th></th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="address in addresses" v-bind:key="address.id">
                        <td><router-link :to="{ name: 'client_adresses_add', params: { client_id: client_id, id: address.id } }"  title="Редактировать">{{address.post_code}}</router-link></td>
                        <td><router-link :to="{ name: 'client_adresses_add', params: { client_id: client_id, id: address.id } }"  title="Редактировать">{{address.city}}</router-link></td>
                        <td><router-link :to="{ name: 'client_adresses_add', params: { client_id: client_id, id: address.id } }"  title="Редактировать">{{address.street}}</router-link></td>
                        <td><router-link :to="{ name: 'client_adresses_add', params: { client_id: client_id, id: address.id } }"  title="Редактировать">{{address.building}}</router-link></td>
                        <td><router-link :to="{ name: 'client_adresses_add', params: { client_id: client_id, id: address.id } }"  title="Редактировать">{{address.comment}}</router-link></td>
                        <td><p v-if="address.to_boxbo_office">На Склад Boxbo</p></td>
                        <td><button type="button" class="btn danger btn-sm disabled" @click="deleteAddress(address)">Удалить</button></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
import ClientLinks from "../../components/ClientLinks";
export default {
  name: 'client_adresses',
  components: {
    Header,
    Menu,
    ClientLinks,
  },
  data() {
      return {
        addresses: { }
      }
  },
  props: {
    client_id: null
  },
  computed: {
      ...mapGetters(['ROLE_IS'])
  },
  methods: {
      ...mapMutations(['CLEAR_USER']),
    loadAdresses: function () {
      Http.getClientAddressList(this.client_id)
        .then(resp => {
          this.addresses = resp.data.results;
        })
        .catch(() => console.error('Loading error'))
    },
    deleteAddress: function (address) {
      address.deleted = true
      Http.deleteClientAddressList(address)
        .then(_ => {
            this.loadAdresses()
        })
        .catch(() => console.error('Loading error'))
    }
  },
  created() {
    this.loadAdresses()
  }
}
</script>
