<template>
  <div class="_wrapper">
    <h1>Price: {{Total}}</h1>
    <hr/>
    <div class="_loading" v-if="isLoading">
      <div class="glyph-icon remove-border demo-icon tooltip-button icon-spin-1 icon-spin" title="" data-original-title="icon-spin-1"></div>
      Загрузка...
    </div>
    <div class="_list" v-else>
      <div class="_list_item" v-for="item in dataList" :key="item.id">
        <div class="_list_text">{{item}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '../../services/Http'

export default {
  data(){
    return {
      isLoading: false,
      dataList: [],
      Total: 0,
      comment: ''
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      value: [String, Number],
      required: true
    },
  },
  mounted(){
    this.fetchDetailPrice();
  },
  methods: {
    async fetchDetailPrice(){
      this.isLoading = true;
      const {data: {notes, total}} = await Http.getParcelPriceDetail(this.id);
      this.dataList = notes;
      this.Total = total;
      this.isLoading = false;
    },
  }
}
</script>

<style lang="scss" scoped>
  ._wrapper{
    margin-top: 32px;
    margin-bottom: 32px;
    margin: 0 auto;
  }
  ._title{
    margin-bottom: 24px;
  }
  ._loading{
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  ._list{

  }
  ._list_item{
    &+._list_item{
      padding-top: 8px;
      margin-top: 8px;
      border-top-width: 1px;
      border-top-style: dashed;
      border-color: #dfe8f1;
    }
  }
  ._list_title{
    font-size: 12px;
    font-style: italic;
    color: #777;
  }
  ._list_text{

  }
  ._create{
    margin-top: 24px;
  }
</style>
