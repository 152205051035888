<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Статистика / отчеты</h2>
          </div>

          <div class="panel">
            <div class="panel-body">


            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
import Http from '../../../services/Http'
export default {
  name: 'stats',
  components: {
    Header,
    Menu
  },
  data() {
    return {
      // calcConfig: []
    }
  },
  computed: {
    ...mapGetters(['ROLE_IS'])
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
  },
  created() {
    // this.getCalcConfig();
  }
}
</script>
