import router from '@/router'

export default {
    // auth
    SET_USER (state, user) {
        state.USER = user;
        localStorage.setItem('token', user.token);
    },

    CLEAR_USER (state) {
        state.USER = {};
        localStorage.removeItem('token');
        router.push({ name: 'login'});
    },

    setUser(state, payload) {
      state.USERS[payload.id] = payload.user
    },
}
