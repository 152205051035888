<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Покупки для клиентов</h2>
          </div>
          <div class="panel">
            <div class="panel-body">
              <form @submit.prevent="searchBarcode">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="search_text" placeholder="Search for...">
                      <span class="input-group-btn">
                        <button class="btn btn-default" type="submit">Go!</button>
                      </span>
                    </div><!-- /input-group -->
                  </div><!-- /.col-lg-6 -->
                </div><!-- /.row -->
              </form>
              <hr/>
              <div class="example-box-wrapper">
                <table class="table table-condensed table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th></th>
                    <th>Дата</th>
                    <th>Трек</th>
                    <th>Описание</th>
                    <th colspan="2">Клиент</th>
                    <th>Статус</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="parcel in getParcels" v-bind:key="parcel.id">
                    <td><router-link :to="{ name: 'parcels_edit', params: { id: parcel.id } }" class="sfActive" title="Отправление">{{parcel.id}}</router-link></td>
                    <td>
                      <span v-if="parcel.boxbo_buy">
                        <i class="glyph-icon icon-life-ring"></i>
                      </span>
                    </td>
                    <td><router-link :to="{ name: 'parcels_edit', params: { id: parcel.id } }" class="sfActive" title="Отправление">{{parcel.add_date | msk_date}}</router-link></td>
                    <td><router-link :to="{ name: 'parcels_edit', params: { id: parcel.id } }" class="sfActive" title="Отправление">{{parcel.ref_barcode}}</router-link></td>
                    <td>
                      <div class="_parcel_links">
                        <span v-for="(item, ind) in parcel.items" :key="item.id" class="_parcel_link">
                          <span v-if="ind !== 0">, </span>
                          <a :href="item.url" target="_blank">{{item.description}}</a>
                        </span>
                      </div>
                    </td>
                    <td><Client :id="parcel.user"></Client></td>
                    <td><button v-if="parcel.status === 'received_usa'" type="button" @click="createParcelHWB(parcel.id)" class="btn btn-success">Создать HWB</button></td>
                    <td><div class="btn-group">
                      <router-link  tag="button" :to="{ name: 'parcels_edit', params: { id: parcel.id } }" class="btn btn-info" style="max-width: 180px;overflow: hidden;text-overflow: ellipsis;">{{statusDict[parcel.status]}}</router-link>
                      <button type="button" class="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                        <span class="caret"></span>
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      <ul class="dropdown-menu float-right" role="menu">
                        <li v-for="(status, statusKey) in statusDict" :key="statusKey">
                          <a href="#" @click.prevent="setStatus(parcel.id,statusKey)">
                            {{status}}
                          </a>
                        </li>

                        <li class="divider"></li>
                        <li><a href="#">Подготовить к отправке</a></li>
                      </ul>
                    </div></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
import Client from "@/pages/main/admin/clients/Client";
import statusDict from "@/services/statusDict.js"

export default {
  name: 'parcels',
  components: {
    Header,
    Menu,
    Client
  },
  data() {
    return {
      filter_selected: 'user_order',
      parcels: {
        results: [],
      },
      search_text: null,
      statusDict,
    }
  },
  computed: {
    ...mapGetters(['ROLE_IS']),
    getParcels(){
      let filtered = ['canceled','delivered']
      let items = this.parcels.results.filter( p => !filtered.includes(p.status))
      // if(this.filter_selected){
      //   // console.log(this.filter_selected)
      //   items = this.parcels.results.filter( p => p.status === this.filter_selected)
      // }
      return items
    }
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
    searchBarcode: function() {
      Http.searchParcelsList(this.search_text)
        .then(resp => {
          this.parcels = resp.data;
        })
        .catch(() => console.error('Loading error'))
    },
    loadParcelsList: function () {
      Http.getParcelsListQuery({
        status: this.filter_selected,
        boxbo_buy: 1
      })
        .then(resp => {
          this.parcels = resp.data;
        })
        .catch(() => console.error('Loading error'))
    },
    createParcelHWB: function (parcel_id){
      Http.createHWB(parcel_id)
        .then(resp => {
          if(resp.data.status === "ok"){
            this.loadParcelsList()
          }else{
            alert(resp.data.errMessage)
          }
        })
        .catch(error => {
          console.error('Create HWB load error')
          alert(error.response.data.errMessage)
        })
    },
    setStatus: function (id, status) {
      Http.setParcelStatus(id, {status: status}).then(resp => {
        // this.loadParcelsList()

        const timestamp = new Date().valueOf()

        let Index = this.parcels.results.findIndex(function (item) {
          return parseInt(item.id) === parseInt(id)
        })

        if(this.parcels.results[Index]) {
          this.parcels.results[Index]["status"] = status
          this.parcels.results[Index]["key"] = `${id}${timestamp}`
        }

      })
      .catch(() => console.error('Loading error'))
    }
  },
  created() {
    this.loadParcelsList()
  }
}
</script>
<style lang="scss" scoped>
  ._parcel_links {
      white-space: normal;
      max-width: 160px;
      overflow: hidden;
  }
  ._parcel_link{
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
