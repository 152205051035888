<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">

          <div id="page-title">
            <h2>Добавить баланс: {{client.first_name}} {{client.last_name}}, {{client.email}}</h2>
          </div>

          <div class="panel">
            <div class="panel-body">
              <div v-if="client_id" class="example-box-wrapper">
                <client-links :client_id="client_id"></client-links>
              </div>
              <h3 class="title-hero">
                Добавить или списать денег с баланса пользователя
              </h3>
              <div class="example-box-wrapper">
                <div class="example-box-wrapper">
                  <form class="form-horizontal bordered-row" @submit.prevent="saveMoney">
                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="credit">Credit (Пополнение)</label>
                      <div class="col-sm-6">
                        <input type="number" step="0.01" v-model="money.credit" class="form-control" id="credit" placeholder="">
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="debit">Debit (Списание)</label>
                      <div class="col-sm-6">
                        <input type="number" step="0.01" v-model="money.debit" class="form-control" id="debit" placeholder="">
                      </div>
                    </div>



                    <div class="form-group">
                      <label class="col-sm-2 control-label" for="comment">Комментарий</label>
                      <div class="col-sm-6">
                        <textarea v-model="money.comment" class="form-control" id="comment">

                        </textarea>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-2 control-label"></label>
                      <div class="col-sm-6">
                        <button class="btn btn-success" type="submit">Сохранить</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
import ClientLinks from "../../components/ClientLinks";

export default {
  name: 'money_add',
  components: {
    Header,
    Menu,
    ClientLinks
  },
  data() {
    return {
      money:{
        debit: null,
        credit: null,
        comment: null,
      },
      client: {}
    }
  },
  props: {
    client_id: null
  },
  computed: {
    ...mapGetters(['ROLE_IS'])
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
    _getClient: function (client_id) {
      if(client_id) {
        Http.getClient(client_id)
          .then(resp => {
            this.client = resp.data.results[0];
          })
          .catch(() => console.error('Loading error'))
      }
    },
    saveMoney: function () {
      Http.addMoney(this.client_id, this.money)
        .then(() => {
          $.jGrowl("Сохранено", {
            sticky: false,
            position: 'top-right',
            theme: 'bg-green'
          });
          this.$router.push({ name: 'client_balance', params: { client_id: this.client_id } });

        })
        .catch((err) => {
          $.jGrowl(err.response.data.errMessage, {
            sticky: false,
            position: 'top-right',
            theme: 'bg-red'
          });
        })
    }
  },
  created() {
    this._getClient(this.client_id)
  }
}
</script>
