export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/identification/Login')
  },
  {
    path: '/lk/index',
    name: 'index',
    component: () => import('@/pages/main/Index'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/stats',
    name: 'stats',
    component: () => import('@/pages/main/admin/Stats'),
    meta: { requiresAuth: true, permissions: ['admin','marketing'] }
  },
  {
    path: '/lk/passports/:client_id',
    props: true,
    name: 'client_passports',
    component: () => import('@/pages/main/admin/PassportsAll'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/passport/:client_id/:passport_id',
    props: true,
    name: 'client_passport_add',
    component: () => import('@/pages/main/admin/PassportsAdd'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/parcels/new',
    name: 'parcels_new',
    component: () => import('@/pages/main/admin/NewParcels'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/parcels',
    name: 'parcels',
    component: () => import('@/pages/main/admin/Parcels'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/boxbo-buy',
    name: 'boxbo-buy',
    component: () => import('@/pages/main/admin/BoxboBuy'),
    meta: { requiresAuth: true, permissions: ['admin','shopper'] }
  },
  {
    path: '/lk/orders',
    name: 'orders',
    component: () => import('@/pages/main/admin/Orders'),
    meta: { requiresAuth: true, permissions: ['admin','shopper'] }
  },
  {
    path: '/lk/orders/:order_id',
    props: true,
    name: 'order_info',
    component: () => import('@/pages/main/admin/OrderInfo'),
    meta: { requiresAuth: true, permissions: ['admin','shopper'] }
  },
  {
    path: '/lk/parcels/add/:client_id',
    props: true,
    name: 'parcels_add',
    component: () => import('@/pages/main/admin/ParcelsEdit'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/parcels/edit/:id',
    props: true,
    name: 'parcels_edit',
    component: () => import('@/pages/main/admin/ParcelsEdit'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/hwb',
    name: 'hwb',
    component: () => import('@/pages/main/admin/Hwb'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/awb',
    name: 'awb',
    component: () => import('@/pages/main/admin/Awb'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/settings',
    name: 'settings',
    component: () => import('@/pages/main/admin/Settings'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/clients',
    name: 'clients_all',
    component: () => import('@/pages/main/admin/Clients'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/client/:client_id',
    props: true,
    name: 'client_info',
    component: () => import('@/pages/main/admin/Client'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/client/add/',
    name: 'client_add',
    component: () => import('@/pages/main/admin/ClientAdd'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/client/add/:client_id',
    props: true,
    name: 'client_edit',
    component: () => import('@/pages/main/admin/ClientAdd'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/adresses/:client_id',
    props: true,
    name: 'client_adresses',
    component: () => import('@/pages/main/admin/Adresses'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/adresses/:client_id/:id',
    props: true,
    name: 'client_adresses_add',
    component: () => import('@/pages/main/admin/AdressesAdd'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/locker/:client_id/:id',
    props: true,
    name: 'client_adresses_add_locker',
    component: () => import('@/pages/main/admin/LockerAdd'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/balance',
    name: 'balance_all',
    component: () => import('@/pages/main/admin/Balance'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/balance/:client_id',
    props: true,
    name: 'client_balance',
    component: () => import('@/pages/main/admin/BalanceClient'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/balance/add/:client_id',
    props: true,
    name: 'client_balance_add',
    component: () => import('@/pages/main/admin/BalanceAdd'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/bonuses',
    name: 'bonuses_all',
    component: () => import('@/pages/main/admin/Bonuses'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '/lk/bonuses/:client_id',
    props: true,
    name: 'client_bonuses',
    component: () => import('@/pages/main/admin/BonusesClient'),
    meta: { requiresAuth: true, permissions: ['admin'] }
  },
  {
    path: '*',
    redirect: { name: 'login' }
  }
]

// requiresAuth: true  - требует рефрешнуть токен и забрать роли. Для залогированных страниц
// permissions: ['client'] - только юзеры данной роли
