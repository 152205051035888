var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('Menu'),_c('div',{attrs:{"id":"page-content-wrapper"}},[_c('div',{attrs:{"id":"page-content"}},[_c('div',{staticClass:"container"},[_c('div',{attrs:{"id":"page-title"}},[_c('h2',[_vm._v(" Клиент #"+_vm._s(_vm.client.id)+" "+_vm._s(_vm.client.login)+" "+_vm._s(_vm.client.last_name)+" "+_vm._s(_vm.client.first_name)+" "+_vm._s(_vm.client.mobile_phone)+" ")])]),_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body"},[_c('h3',{staticClass:"title-hero"},[_vm._v("Основная информация")]),(!_vm.client.is_email_active)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" Email не подтвержден ")]):_vm._e(),_c('client-links',{attrs:{"client_id":_vm.client_id}})],1)]),_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body"},[_c('h3',{staticClass:"title-hero"},[_vm._v("Посылки клиента")]),_c('div',{staticClass:"btn-group",attrs:{"role":"group","aria-label":"..."}},[_vm._l((_vm.statusDict),function(status,statusKey){return _c('button',{key:statusKey,staticClass:"btn",class:{
                  'btn-black-opacity-alt': _vm.filter_selected === statusKey,
                  'btn-default': _vm.filter_selected !== statusKey,
                },attrs:{"type":"button"},on:{"click":function($event){return _vm.filterBy(statusKey)}}},[_vm._v(" "+_vm._s(status)+" ")])}),_c('button',{staticClass:"btn",class:{
                  'btn-black-opacity-alt': _vm.filter_selected === null,
                  'btn-default': _vm.filter_selected !== null,
                },attrs:{"type":"button"},on:{"click":function($event){return _vm.filterBy(null)}}},[_vm._v(" ALL ")])],2),_c('hr'),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchBarcode($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_text),expression:"search_text"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search for..."},domProps:{"value":(_vm.search_text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search_text=$event.target.value}}}),_vm._m(0)])])])]),_c('hr'),_c('div',{staticClass:"example-box-wrapper"},[_c('table',{staticClass:"table table-condensed table-hover"},[_vm._m(1),_c('tbody',_vm._l((_vm.getParcels),function(parcel){return _c('tr',{key:parcel.id},[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],attrs:{"type":"checkbox"},domProps:{"value":parcel.id,"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,parcel.id)>-1:(_vm.selected)},on:{"change":function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=parcel.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}}}})]),_c('td',[_c('router-link',{staticClass:"sfActive",attrs:{"to":{
                          name: 'parcels_edit',
                          params: { id: parcel.id },
                        },"title":"Отправление"}},[_vm._v(_vm._s(parcel.id))])],1),_c('td',[(parcel.boxbo_buy)?_c('span',[_c('i',{staticClass:"glyph-icon icon-life-ring"})]):_vm._e(),(parcel.delivery === 'euro')?_c('span',{staticClass:"text-primary",attrs:{"title":"Delivery: euro"}},[_c('i',{staticClass:"glyphicon glyphicon-flash"})]):_vm._e()]),_c('td',[_c('router-link',{staticClass:"sfActive",attrs:{"to":{
                          name: 'parcels_edit',
                          params: { id: parcel.id },
                        },"title":"Отправление"}},[_vm._v(_vm._s(_vm._f("msk_date")(parcel.add_date)))])],1),_c('td',[_c('router-link',{staticClass:"sfActive",attrs:{"to":{
                          name: 'parcels_edit',
                          params: { id: parcel.id },
                        },"title":"Отправление"}},[_vm._v(_vm._s(parcel.ref_barcode))])],1),_c('td',[_c('div',{staticClass:"_parcel_links"},_vm._l((parcel.items),function(item,ind){return _c('span',{key:item.id,staticClass:"_parcel_link"},[(ind !== 0)?_c('span',[_vm._v(", ")]):_vm._e(),_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.description))])])}),0)]),_c('td'),_c('td',[_c('div',{staticClass:"btn-group"},[_c('router-link',{staticClass:"btn btn-info",staticStyle:{"max-width":"180px","overflow":"hidden","text-overflow":"ellipsis"},attrs:{"tag":"button","to":{
                            name: 'parcels_edit',
                            params: { id: parcel.id },
                          }}},[_vm._v(_vm._s(_vm.statusDict[parcel.status]))]),_vm._m(2,true),_c('ul',{staticClass:"dropdown-menu float-right",attrs:{"role":"menu"}},[_vm._l((_vm.statusDict),function(status,statusKey){return _c('li',{key:statusKey},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setStatus(parcel.id, statusKey)}}},[_vm._v(" "+_vm._s(status)+" ")])])}),_c('li',{staticClass:"divider"}),_vm._m(3,true)],2)],1)])])}),0)])]),_c('Comments',{attrs:{"type":"user","id":_vm.client_id}}),(_vm.selected.length > 0)?_c('div',{staticClass:"_floating-footer-wrapper"},[_c('div',{staticClass:"_floating-footer"},[_c('div',{staticClass:"_floating-footer__caption"},[_vm._v(" Массовое действие над "+_vm._s(_vm.selected.length)+" посылками ")]),_c('div',{staticClass:"_floating-footer__btn btn-group dropup"},[_c('button',{staticClass:"btn btn-info",attrs:{"data-toggle":"dropdown","aria-expanded":"true"}},[_vm._v(" Сменить статус ")]),_vm._m(4),_c('ul',{staticClass:"dropdown-menu float-right",attrs:{"role":"menu"}},[_vm._l((_vm.statusDict),function(status,statusKey){return _c('li',{key:statusKey},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setStatusMass(statusKey)}}},[_vm._v(" "+_vm._s(status)+" ")])])}),_c('li',{staticClass:"divider"}),_vm._m(5)],2)]),_c('button',{staticClass:"_floating-footer__btn btn btn-info",on:{"click":_vm.uniteParcels}},[_vm._v(" Объединить посылки ")])])]):_vm._e()],1)])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"input-group-btn"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"submit"}},[_vm._v(" Go! ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("#")]),_c('th'),_c('th',[_vm._v("Дата")]),_c('th',[_vm._v("Трек")]),_c('th',[_vm._v("Описание")]),_c('th'),_c('th',[_vm._v("Статус")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-info dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown","aria-expanded":"true"}},[_c('span',{staticClass:"caret"}),_c('span',{staticClass:"sr-only"},[_vm._v("Toggle Dropdown")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Подготовить к отправке")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-info dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown","aria-expanded":"true"}},[_c('span',{staticClass:"caret"}),_c('span',{staticClass:"sr-only"},[_vm._v("Toggle Dropdown")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Подготовить к отправке")])])}]

export { render, staticRenderFns }