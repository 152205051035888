<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Документы HWB</h2>
          </div>

          <div class="panel">
            <div class="panel-body">
              <h3 class="title-hero">
                Документы HWB
              </h3>
              <div class="example-box-wrapper">
                <table class="table">
                  <thead>
                  <tr>
                    <th></th>
                    <th>HWBRefNumber</th>
                    <th>HWBNumber</th>
                    <th>status</th>
                    <th>Size</th>
                    <th>Weight</th>
                    <th>Price</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="hwb in HwbList" :key="hwb.HWBRefNumber">
                    <td><input type="checkbox" v-model="hwb.checked"></td>
                    <td>{{hwb.HWBRefNumber}}</td>
                    <td>{{hwb.HWBNumber}}</td>
                    <td>{{hwb.status}}</td>
                    <td>{{hwb.length}}x{{hwb.width}}x{{hwb.depth}}</td>
                    <td>{{hwb.weight}}</td>
                    <td>{{hwb.price}}</td>

                    <td><button type="button" class="btn btn-success" @click="updateHWB(hwb.HWBNumber)">Update</button></td>
                  </tr>
                  </tbody>
                </table>
                <div class="form-group">
                  <div class="col-sm-6">
                    <button class="btn btn-success" type="submit" @click="createAWB">Создать AWB</button>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
// import Index from "@/pages/main";
export default {
  name: 'hwb',
  components: {
    // Index,
    Header,
    Menu
  },
  data() {
    return {
      HwbList: [],
    }
  },
  computed: {
    ...mapGetters(['ROLE_IS'])
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
    updateHWB: function (HWBNumber){
      Http.updateHWB(HWBNumber)
        .then(resp => {
          $.jGrowl("Обновлено", {
            sticky: false,
            position: 'top-right',
            theme: 'bg-green'
          });
        })
        .catch(() => console.error('Loading error'))
    },
    loadHWB: function () {
      Http.getHWB()
        .then(resp => {
          // console.log( resp)
          this.HwbList = resp.data.results;
        })
        .catch(() => console.error('Loading error'))
    },
    createAWB: function (){

      let ManifestNumber = prompt("ManifestNumber 555-XXXXXXXX");

      if(ManifestNumber.length === 0){
        alert("Нужно указать номер AWB")
        return
      }

      let manifest_data = {
        'ManifestNumber': ManifestNumber,
        'HWB': [],
      }

      if(this.HwbList.filter(hwb => hwb.checked).length === 0){
        alert("Нужно выбрать HWB")
        return
      }

      this.HwbList.filter(hwb => hwb.checked).forEach( hwb => {
        manifest_data.HWB.push({
          'HWBNumber': hwb.HWBNumber
        })
      })

      Http.createAWB(manifest_data)
        .then(resp => {
          $.jGrowl("AWB Создан", {
            sticky: false,
            position: 'top-right',
            theme: 'bg-green'
          });
        })
        .catch(() => console.error('createAWB: Loading error'))

      // console.log(manifest_data)

      // Http.createAWB()
    }
  },
  created() {
    this.loadHWB()
  }
}
</script>
