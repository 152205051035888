var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"example-box-wrapper"},[(_vm.client_id)?_c('div',[_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
      name: 'client_info',
      params: { client_id: _vm.client_id },
    },"title":"Все посылки"}},[_vm._v("Посылки")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
      name: 'parcels_add',
      params: { client_id: _vm.client_id },
    },"title":"Добавить посылку"}},[_vm._v("Добавить посылку")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
      name: 'client_edit',
      params: { client_id: _vm.client_id },
    },"title":"Редактирование клиента"}},[_vm._v("Редактирование клиента")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
      name: 'client_passports',
      params: { client_id: _vm.client_id },
    },"title":"Паспорта"}},[_vm._v("Паспорта")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
      name: 'client_adresses',
      params: { client_id: _vm.client_id },
    },"title":"Адреса доставки"}},[_vm._v("Адреса доставки")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
      name: 'client_balance',
      params: { client_id: _vm.client_id },
    },"title":"Баланс клиента"}},[_vm._v("Баланс")]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
      name: 'client_bonuses',
      params: { client_id: _vm.client_id },
    },"title":"Бонусы клиента"}},[_vm._v("Бонусы")]),_vm._v(" "),_c('button',{staticClass:"btn btn-danger",attrs:{"title":"Логин за юзера"},on:{"click":function($event){$event.preventDefault();return _vm.loginAsUser($event)}}},[_vm._v("Логин за юзера")])],1):_c('div',[_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{
      name: 'parcels_new',
    },"title":"Разбор склада"}},[_vm._v("Разбор склада")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }