const buyStatusDict = {
  new: "Новый заказ",
  ordered: "Заказано",
  waiting: "Ожидается получение",
  received: "Получено на складе в США",
  delivered: "Доставлено",
  canceled: "Отменено",
};

export default buyStatusDict;
