<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>
              Клиент #{{ client.id }} {{ client.login }} {{ client.last_name }}
              {{ client.first_name }} {{ client.mobile_phone }}
            </h2>
          </div>
          <div class="panel">
            <div class="panel-body">
              <h3 class="title-hero">Основная информация</h3>
              <div
                v-if="!client.is_email_active"
                class="alert alert-danger"
                role="alert"
              >
                Email не подтвержден
              </div>
              <client-links :client_id="client_id"></client-links>
            </div>
          </div>
          <div class="panel">
            <div class="panel-body">
              <h3 class="title-hero">Посылки клиента</h3>
              <div class="btn-group" role="group" aria-label="...">
                <button
                  type="button"
                  class="btn"
                  :class="{
                    'btn-black-opacity-alt': filter_selected === statusKey,
                    'btn-default': filter_selected !== statusKey,
                  }"
                  @click="filterBy(statusKey)"
                  v-for="(status, statusKey) in statusDict"
                  :key="statusKey"
                >
                  {{ status }}
                </button>

                <button
                  type="button"
                  class="btn"
                  :class="{
                    'btn-black-opacity-alt': filter_selected === null,
                    'btn-default': filter_selected !== null,
                  }"
                  @click="filterBy(null)"
                >
                  ALL
                </button>
              </div>
              <hr />
              <form @submit.prevent="searchBarcode">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="search_text"
                        placeholder="Search for..."
                      />
                      <span class="input-group-btn">
                        <button class="btn btn-default" type="submit">
                          Go!
                        </button>
                      </span>
                    </div>
                    <!-- /input-group -->
                  </div>
                  <!-- /.col-lg-6 -->
                </div>
                <!-- /.row -->
              </form>
              <hr />
              <div class="example-box-wrapper">
                <table class="table table-condensed table-hover">
                  <thead>
                    <tr>
                      <th></th>
                      <th>#</th>
                      <th></th>
                      <th>Дата</th>
                      <th>Трек</th>
                      <th>Описание</th>
                      <th></th>
                      <th>Статус</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="parcel in getParcels" v-bind:key="parcel.id">
                      <td>
                        <input
                          type="checkbox"
                          :value="parcel.id"
                          v-model="selected"
                        />
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'parcels_edit',
                            params: { id: parcel.id },
                          }"
                          class="sfActive"
                          title="Отправление"
                          >{{ parcel.id }}</router-link
                        >
                      </td>
                      <td>
                        <span v-if="parcel.boxbo_buy">
                          <i class="glyph-icon icon-life-ring"></i>
                        </span>
                        <span v-if="parcel.delivery === 'euro'" title="Delivery: euro" class="text-primary">
                          <i class="glyphicon glyphicon-flash"></i>
                        </span>
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'parcels_edit',
                            params: { id: parcel.id },
                          }"
                          class="sfActive"
                          title="Отправление"
                          >{{ parcel.add_date | msk_date }}</router-link
                        >
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'parcels_edit',
                            params: { id: parcel.id },
                          }"
                          class="sfActive"
                          title="Отправление"
                          >{{ parcel.ref_barcode }}</router-link
                        >
                      </td>
                      <td>
                        <div class="_parcel_links">
                          <span v-for="(item, ind) in parcel.items" :key="item.id" class="_parcel_link">
                            <span v-if="ind !== 0">, </span>
                            <a :href="item.url" target="_blank">{{item.description}}</a>
                          </span>
                        </div>
                      </td>
                      <td>
                      </td>
                      <td>
                        <div class="btn-group">
                          <router-link
                            tag="button"
                            :to="{
                              name: 'parcels_edit',
                              params: { id: parcel.id },
                            }"
                            class="btn btn-info"
                            style="
                              max-width: 180px;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                            >{{ statusDict[parcel.status] }}</router-link
                          >
                          <button
                            type="button"
                            class="btn btn-info dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="true"
                          >
                            <span class="caret"></span>
                            <span class="sr-only">Toggle Dropdown</span>
                          </button>
                          <ul class="dropdown-menu float-right" role="menu">
                            <li
                              v-for="(status, statusKey) in statusDict"
                              :key="statusKey"
                            >
                              <a
                                href="#"
                                @click.prevent="setStatus(parcel.id, statusKey)"
                              >
                                {{ status }}
                              </a>
                            </li>

                            <li class="divider"></li>
                            <li><a href="#">Подготовить к отправке</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Comments type="user" :id="client_id"/>
              <div class="_floating-footer-wrapper" v-if="selected.length > 0">
                <div class="_floating-footer">
                  <div class="_floating-footer__caption">
                    Массовое действие над {{ selected.length }} посылками
                  </div>
                  <div class="_floating-footer__btn btn-group dropup">
                    <button
                      class="btn btn-info"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      Сменить статус
                    </button>
                    <button
                      type="button"
                      class="btn btn-info dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <span class="caret"></span>
                      <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <ul class="dropdown-menu float-right" role="menu">
                      <li
                        v-for="(status, statusKey) in statusDict"
                        :key="statusKey"
                      >
                        <a href="#" @click.prevent="setStatusMass(statusKey)">
                          {{ status }}
                        </a>
                      </li>

                      <li class="divider"></li>
                      <li><a href="#">Подготовить к отправке</a></li>
                    </ul>
                  </div>
                  <button
                    class="_floating-footer__btn btn btn-info"
                    @click="uniteParcels"
                  >
                    Объединить посылки
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from "@/services/Http";
import { mapMutations, mapGetters } from "vuex";
import Header from "../../components/SiteHeader";
import Menu from "../../components/SiteMenu";
import ClientLinks from "../../components/ClientLinks";
import statusDict from "@/services/statusDict.js";
import Comments from '../../components/Comments.vue';

export default {
  name: "client",
  components: {
    Header,
    Menu,
    ClientLinks,
    Comments,
  },
  data() {
    return {
      selected: [],
      client: {
        groups: [2],
      },
      filter_selected: null,
      parcels: {
        results: [],
      },
      search_text: null,
      statusDict,
    };
  },
  props: {
    client_id: null,
  },
  computed: {
    ...mapGetters(["ROLE_IS"]),
    getParcels() {
      let filtered = ["canceled", "delivered"];
      let items = this.parcels.results.filter(
        (p) => !filtered.includes(p.status)
      );
      // if(this.filter_selected){
      //   // console.log(this.filter_selected)
      //   items = this.parcels.results.filter( p => p.status === this.filter_selected)
      // }
      return items;
    },
  },
  methods: {
    ...mapMutations(["CLEAR_USER"]),
    getClient: function (client_id) {
      Http.getClient(client_id)
        .then((resp) => {
          this.client = resp.data.results[0];
        })
        .catch(() => console.error("Loading error"));
    },
    filterBy: function (selected) {
      this.$router.push({name: 'client_info', query: { filter: selected }});
      this.filter_selected = selected;
      this.loadParcelsList();
    },
    searchBarcode: function () {
      Http.getParcelsListQuery({
        user: this.client_id,
        status: this.filter_selected,
        search: this.search_text,
      })
        .then((resp) => {
          this.parcels = resp.data;
        })
        .catch(() => console.error("Loading error"));
    },
    loadParcelsList: function () {
      Http.getParcelsListQuery({
        user: this.client_id,
        status: this.filter_selected,
      })
        .then((resp) => {
          this.parcels = resp.data;
        })
        .catch(() => console.error("Loading error"));
    },
    createParcelHWB: function (parcel_id) {
      Http.createHWB(parcel_id)
        .then((resp) => {
          if (resp.data.status === "ok") {
            this.loadParcelsList();
          } else {
            alert(resp.data.errMessage);
          }
        })
        .catch((error) => {
          console.error("Create HWB load error");
          alert(error.response.data.errMessage);
        });
    },
    setStatus: function (id, status) {
      Http.setParcelStatus(id, { status: status })
        .then((resp) => {
          // this.loadParcelsList()

          const timestamp = new Date().valueOf();

          let Index = this.parcels.results.findIndex(function (item) {
            return parseInt(item.id) === parseInt(id);
          });

          if (this.parcels.results[Index]) {
            this.parcels.results[Index]["status"] = status;
            this.parcels.results[Index]["key"] = `${id}${timestamp}`;
          }
        })
        .catch(() => console.error("Loading error"));
    },
    setStatusMass: async function (status) {
      const promisesArr = this.selected.map((element) => {
        return Http.setParcelStatus(element, { status: status });
      });
      await Promise.all(promisesArr);
      this.loadParcelsList();
      this.selected = [];
    },
    uniteParcels: function () {
      alert("Not Implemented");
    },
  },
  created() {
    this.getClient(this.client_id);
    if( this.$route.query.filter === undefined ){
      this.filter_selected = null;
    } else {
      this.filter_selected = this.$route.query.filter;
    }
    this.loadParcelsList();
  },
};
</script>

<style>
._floating-footer-wrapper {
  height: 72px;
}
._floating-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  background-color: rgb(232 234 236 / 90%);
  z-index: 99;
}
._floating-footer__caption {
  font-weight: bold;
}
._floating-footer__btn {
  margin-left: 8px;
}
._parcel_links {
  white-space: normal;
  max-width: 160px;
  overflow: hidden;
}
._parcel_link{
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
