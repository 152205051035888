import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import routes from './routes'

Vue.use(Router)

const router = new Router({
  linkActiveClass: 'is-active',
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

// Если в роуте нет requiresAuth, то пропускаем дальше. Остальной код не выполняется.
// Далее каждый роут рефрешит токен, далее сравниваем его доступную роль с существующей и пропускаем или нет
router.beforeEach((to, from, next) => {
  if (!to.matched.some(record => record.meta.requiresAuth)) return next();

  store.dispatch('REFRESH_TOKEN')
    .then(() => {
      if ( !to.matched.some(record => record.meta.permissions && record.meta.permissions.length ) ) return next();

      let allowedRouteWithRole = to.matched.some(record => record.meta.permissions.some(role => store.getters.ROLE_IS(role)) );
      allowedRouteWithRole ? next() : next(from.path);
    })
    .catch(() => next({ name: 'login' }))
})

export default router
