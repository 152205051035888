<template>
  <div id="page-sidebar">
    <div class="scroll-sidebar" style="height: 100%">
      <ul v-if="ROLE_IS('admin')" id="sidebar-menu" class="sf-js-enabled sf-arrows">
        <li class="header"><span>Админ</span></li>
        <li>
          <router-link :to="{ name: 'stats' }" class="sfActive" title="Boxbo покупает">
            <i class="glyph-icon icon-area-chart"></i>
            <span>Статистика</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'parcels_new' }" class="sfActive" title="Новые">
            <i class="glyph-icon icon-truck"></i>
            <span>Разбор склада</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'parcels' }" class="sfActive" title="Посылки">
            <i class="glyph-icon icon-envelope-o"></i>
            <span>Посылки</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'boxbo-buy' }" class="sfActive" title="Boxbo покупает">
            <i class="glyph-icon icon-shopping-cart"></i>
            <span>Заявки клиентов</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'orders' }" class="sfActive" title="Обработка заказов от клиентов">
            <i class="glyph-icon icon-cc-paypal"></i>
            <span>Заказы в магазинах</span>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'clients_all'}" class="sfActive" title="Клиенты">
            <i class="glyph-icon icon-users"></i>
            <span>Клиенты</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'balance_all'}" class="sfActive" title="Баланс">
            <i class="glyph-icon icon-dollar"></i>
            <span>Платежи клиентов</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'bonuses_all'}" class="sfActive" title="Бонусы">
            <i class="glyph-icon icon-linecons-money"></i>
            <span>Бонусы</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'hwb'}" class="sfActive" title="HWB">
            <i class="glyph-icon icon-file-word-o"></i>
            <span>HWB</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'awb'}" class="sfActive" title="AWB">
            <i class="glyph-icon icon-plane"></i>
            <span>AWB</span>
          </router-link>
        </li>
<!--        <li>-->
<!--          <router-link :to="{ name: 'settings'}" class="sfActive" title="Настройки">-->
<!--            <i class="glyph-icon icon-cog"></i>-->
<!--            <span>Настройки</span>-->
<!--          </router-link>-->
<!--        </li>-->
      </ul><!-- #sidebar-menu -->
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapMutations} from "vuex";

  export default {
    name: 'sitemenu',
    data() {
      return {
        myData: ''
      }
    },
    methods: {
      ...mapMutations(['CLEAR_USER'])
    },
    computed: {
      ...mapGetters(['ROLE_IS'])
    },
  }
</script>
