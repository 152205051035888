<template>
  <div>
    <div>
      <div class="modal-header">
        <div class="_map__delivery-title">Город</div>
        <select v-model="city" class="_map__delivery-select form-control">
          <option v-for="city in cities" :key="city">{{city}}</option>
        </select>
        <div class="_map__delivery-title" v-if="value.postamat.lockerName">
          <div v-if="value.postamat.LockerType === 'АПТ' || value.postamat.LockerType === 'POSTAMAT'">
            Выбранный постамат
          </div>
          <div v-else>
            Выбранный пункт выдачи
          </div>
        </div>
        <div class="_map__delivery-title" v-else>Выберите пункт выдачи</div>
        <div
          class="_map__delivery-postamat-selected"
          v-if="value.postamat.lockerID"
        >
          {{ value.postamat.lockerName }}
          <button class="_map__delivery-postamat-selected-remove" @click="removeLocker">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="8" fill="#0094FF"/>
              <path d="M9.50704 8.00107L11.5048 6.00312C11.9211 5.58678 11.9211 4.91215 11.5048 4.49581C11.0885 4.07947 10.4139 4.07947 9.99762 4.49581L7.9999 6.49494L6.001 4.49463C5.58471 4.07829 4.91015 4.07829 4.49386 4.49463C4.07757 4.91097 4.07757 5.5856 4.49386 6.00194L6.49158 7.9999L4.49386 9.99785C4.07757 10.4142 4.07757 11.0888 4.49386 11.5052C4.70141 11.7139 4.97501 11.8177 5.24743 11.8177C5.51984 11.8177 5.79344 11.7139 6.001 11.5052L7.9999 9.50603L9.99762 11.5052C10.2064 11.7127 10.4788 11.8177 10.7512 11.8177C11.0236 11.8177 11.2972 11.7139 11.5048 11.5052C11.9211 11.0888 11.9211 10.4142 11.5048 9.99785L9.50704 8.00107Z" fill="white"/>
            </svg>
          </button>
        </div>
      </div>
      <!-- :show-all-markers="true" -->
      <yandex-map
        :settings="map.settings"
        :coords="map.coords"
        :zoom="map.zoom"
        :controls="map.controls"
        :cluster-options="map.clusterOptions"
        :use-object-manager="true"
        class="_map__container"
        v-on:balloonopen="balloonopen"
        v-on:balloonclose="balloonclose"
        v-on:map-was-initialized="mapWasInitialized"
      >
        <ymap-marker
          v-for="m in marker.points"
          :key="m.id"
          :marker-id="m.id"
          :coords="[m.lat, m.lon]"
          :icon="markerIcon(m)"
          :properties="ballooncontent(m)"
          cluster-name="1"
        />
        <!--Markers-->
      </yandex-map>
    </div>
  </div>
</template>
<script>

import { yandexMap, ymapMarker } from "vue-yandex-maps";
import axios from 'axios';
import Http from '../../services/Http';

export default {
  components: {
    yandexMap,
    ymapMarker,
  },
  data() {
    return {
      cities: [],
      city: "Москва",
      value: {
        postamat: {
          lockerID : "",
          lockerName : "",
          lockerType : "",
          lockerCity : "",
        }
      },
      map: {
        settings: {
          apiKey: "95f61d5b-c9a2-43fd-9aa7-7f2ba2134f74",
          lang: "ru_RU",
          coordorder: "latlong",
          version: "2.1",
        },
        coords: [55.76, 37.64],
        zoom: 11,
        controls: ["zoomControl", "geolocationControl"],
        clusterOptions: {
          1: {
            clusterOpenBalloonOnClick: true,
          },
        },
      },
      marker: {
        coords: [55.76, 37.64],
        points: [],
      },
      balloonBtn: {},
    };
  },
  props: ["selectedLocker"],
  watch: {
    value:{
      deep: true,
      handler(){
        this.$emit('valueChanged', this.value.postamat.lockerID)
      }
    },
    city() {
      // Наполнение маркеров на карте пунктов выдачи
      if(this.city) {
        let url = `https://boxbopoints.storage.yandexcloud.net/${this.city}.json`;
        axios
          .get(url)
          .then((resp) => {
            this.marker.points = [];
            this.marker.points = resp.data.results.filter(el => {
              return el.pointData.LockerType !== 'Курьерская';
            });
          })
          .catch(() => console.error("Loading error"));
      }
    },
  },
  mounted() {
    console.log(this.selectedLocker)
    // if( this.selectedLocker ){
    //   this.map.coords = [this.selectedLocker.lat, this.selectedLocker.lon ];
    //   this.map.zoom = 17;
    //
    //   this.value.postamat.lockerID = this.selectedLocker.id;
    //   this.value.postamat.lockerName = `${this.selectedLocker.pointData.LockerOwner}: ${this.selectedLocker.pointData.Address}`;
    //   this.value.postamat.lockerType = this.selectedLocker.pointData.LockerType;
    //   this.value.postamat.lockerCity = this.selectedLocker.pointData.city;
    // }

    // Наполнение списка городов
    Http.getShippingCitiesList().then((resp) => {
      this.cities = resp.data.results.map((el) => {
        return el.city;
      });
    });
  },
  methods: {
    mapWasInitialized(el) {
      if( this.selectedLocker ){
        this.city = this.selectedLocker.city;
      } else {
        this.city = "Москва";
      }
    },

    markerIcon(val) {
      let result = {
        layout: "default#image",
        imageHref: "/assets/images/pickpoint.svg",
        imageSize: [43, 64],
        imageOffset: [-21, -64],
      };
      if (val.pointData.LockerContractor === "OZON") {
        result.imageHref = "/assets/images/ozon.svg";
      }
      if (val.pointData.LockerContractor === "5Post") {
        result.imageHref = "/assets/images/5post.svg";
      }

      return result;
    },
    ballooncontent(data) {
      let status = "<span>Закрыто</span>";
      if(data.pointData.Status === "Open") status = "<span class='--green'>Открыто</span>";
      let html = `
        <div class="_map__balloon-text">${data.pointData.Address}</div>
        <div class="_map__balloon-status">Статус: ${status}</div>
        <div class="_map__balloon-schedule">`;

      if(data.pointData.Schedule[0]) html += `<div class="_map__balloon-schedule-item">Пн: ${data.pointData.Schedule[0].opensAt} - ${data.pointData.Schedule[0].closesAt}</div>`;
      if(data.pointData.Schedule[1]) html += `<div class="_map__balloon-schedule-item">Вт: ${data.pointData.Schedule[1].opensAt} - ${data.pointData.Schedule[1].closesAt}</div>`;
      if(data.pointData.Schedule[2]) html += `<div class="_map__balloon-schedule-item">Ср: ${data.pointData.Schedule[2].opensAt} - ${data.pointData.Schedule[2].closesAt}</div>`;
      if(data.pointData.Schedule[3]) html += `<div class="_map__balloon-schedule-item">Чт: ${data.pointData.Schedule[3].opensAt} - ${data.pointData.Schedule[3].closesAt}</div>`;
      if(data.pointData.Schedule[4]) html += `<div class="_map__balloon-schedule-item">Пт: ${data.pointData.Schedule[4].opensAt} - ${data.pointData.Schedule[4].closesAt}</div>`;
      if(data.pointData.Schedule[5]) html += `<div class="_map__balloon-schedule-item">Сб: ${data.pointData.Schedule[5].opensAt} - ${data.pointData.Schedule[5].closesAt}</div>`;
      if(data.pointData.Schedule[6]) html += `<div class="_map__balloon-schedule-item">Вс: ${data.pointData.Schedule[6].opensAt} - ${data.pointData.Schedule[6].closesAt}</div>`;

      html += `</div>
        <a href="#" class="_map__balloon-btn btn btn-primary" data-id="${data.id}" data-type="${data.pointData.LockerType}" data-name="${data.pointData.LockerOwner}: ${data.pointData.Address}">Выбрать этот пункт выдачи</a>`;
      return {
        balloonContentHeader: data.pointData.LockerOwner,
        balloonContentBody: html,
      };
    },
    balloonopen() {
      this.balloonBtn = document.querySelector("._map__balloon-btn");
      if (
        this.value.postamat.lockerID == this.balloonBtn.getAttribute("data-id")
      ) {
        this.switchBtn(false);
      }
      this.balloonBtn.onclick = this.balloonEventListener;
    },
    balloonEventListener(e) {
      e.preventDefault();
      if (
        this.value.postamat.lockerID == this.balloonBtn.getAttribute("data-id")
      ) {
        this.value.postamat.lockerID = "";
        this.value.postamat.lockerName = "";
        this.value.postamat.lockerType = "";
        this.value.postamat.lockerCity = "";
        this.switchBtn(true);
      } else {
        this.value.postamat.lockerID = this.balloonBtn.getAttribute("data-id");
        this.value.postamat.lockerName = this.balloonBtn.getAttribute("data-name");
        this.value.postamat.lockerType = this.balloonBtn.getAttribute("data-type");
        this.value.postamat.lockerCity = this.city;
        this.switchBtn(false);
      }
    },
    balloonclose() {
      this.balloonBtn.removeEventListener(
        "click",
        this.balloonEventListener,
        false
      );
    },
    switchBtn(reverse) {
      if (reverse) {
        this.balloonBtn.classList.remove("btn-success");
        this.balloonBtn.classList.add("btn-primary");
        this.balloonBtn.textContent = "Выбрать этот пункт выдачи";
      } else {
        this.balloonBtn.classList.remove("btn-primary");
        this.balloonBtn.classList.add("btn-success");
        this.balloonBtn.textContent = "Отменить выбор";
      }
    },
    removeLocker(){
      this.value.postamat.lockerID = "";
      this.value.postamat.lockerName = "";
      this.value.postamat.lockerType = "";
      this.value.postamat.lockerCity = "";
      this.switchBtn(true);
    },
  },
};
</script>
<style lang="scss">
._map__delivery-title {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  span{
    font-size: 14px;
    font-weight: normal;
  }
}
._map__delivery-select{
  margin-bottom: 16px;
}
._map__delivery-postamat-selected {
  border: 1px solid #0094FF;
  border-radius: 5px;
  font-size: 14px;
  color: #0094FF;
  margin-bottom: 16px;
  display: inline-flex;
  padding: 8px 16px;
}
._map__delivery-postamat-selected-remove{
  border: 0;
  background-color: transparent;
  padding: 0;
  margin-left: rem(16px);
  padding-top: 2px;
  margin-left: 16px;
}
._map__container{
  width: 100%;
  height: 400px;
}
._map__balloon-subtitle {
  display: block;
  margin: 6px 0;
  font-size: 90%;
  line-height: 1.3;
}
._map__balloon-status{
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 4px;
  margin-top: 8px;
  font-size: 9px;
  letter-spacing: 0.05em;
  span{
    color: #F53C3C;
    &.--green{
      color: #04AF00;
    }
  }
}
._map__balloon-text {
  line-height: 1.3;
}
._map__balloon-schedule{
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
}
._map__balloon-schedule-item{
  width: 33.33%;
  font-size: 11px;
  line-height: 1.1;
  font-family: monospace;
}
._map__balloon-btn {
  height: 32px;
  margin-top: 8px;
  padding: 0 16px;
  white-space: nowrap;
}
</style>
