export default {
    ROLE_IS: state => role => {
        if(!state.USER.groups) return false;

        return state.USER.groups.some(group => group === role)
    },
    getUsers: state => {
      return state.USERS
    },
}
