<template>
  <div class="_wrapper">
    <h3 class="title-hero">Комментарии</h3>
    <div class="_loading" v-if="isLoading">
      <div class="glyph-icon remove-border demo-icon tooltip-button icon-spin-1 icon-spin" title="" data-original-title="icon-spin-1"></div>
      Загрузка...
    </div>
    <div class="_list" v-else>
      <div class="_list_item" v-for="item in commentsList" :key="item.id">
        <div class="_list_title">{{item.added | msk_date}}</div>
        <div class="_list_text">{{item.comment}}</div>
      </div>
    </div>
    <form class="_create" @submit.prevent="postComment">
      <div class="form-group">
        <label class="control-label">Новый комментарий</label>
        <textarea class="form-control" v-model="comment"></textarea>
      </div>
      <button class="btn btn-primary">Отправить</button>
    </form>
  </div>
</template>

<script>
import Http from '../../services/Http'

export default {
  data(){
    return {
      isLoading: false,
      commentsList: [],
      comment: ''
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      value: [String, Number],
      required: true
    },
  },
  mounted(){
    this.fetchComments();
  },
  methods: {
    async fetchComments(){
      this.isLoading = true;
      const {data: {results}} = await Http.getComments(this.type, this.id);
      this.commentsList = results.reverse();
      this.isLoading = false;
    },
    async postComment(){
      await Http.setComment(this.type, this.id, this.comment);
      this.comment = '';
      this.fetchComments();
    }
  }
}
</script>

<style lang="scss" scoped>
  ._wrapper{
    margin-top: 32px;
    margin-bottom: 32px;
    margin: 0 auto;
  }
  ._title{
    margin-bottom: 24px;
  }
  ._loading{
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  ._list{

  }
  ._list_item{
    &+._list_item{
      padding-top: 8px;
      margin-top: 8px;
      border-top-width: 1px;
      border-top-style: dashed;
      border-color: #dfe8f1;
    }
  }
  ._list_title{
    font-size: 12px;
    font-style: italic;
    color: #777;
  }
  ._list_text{

  }
  ._create{
    margin-top: 24px;
  }
</style>
