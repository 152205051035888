<template>
  <div>
    <Header></Header>
    <Menu></Menu>
    <div id="page-content-wrapper">
      <div id="page-content">
        <div class="container">
          <div id="page-title">
            <h2>Новые поступления на склад</h2>
          </div>

          <div class="panel">
            <div class="panel-body">
              <h3 class="title-hero">
                Новые поступления на склад
              </h3>
              <div class="example-box-wrapper">
                <table class="table table-hover table-condensed">
                  <thead>
                  <tr>
                    <th>Дата</th>
                    <th>ID</th>
                    <th>Трек</th>
                    <th>Статус</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="parcel in myData.results" v-bind:key="parcel.id">
                    <td><router-link :to="{ name: 'parcels_edit', params: { id: parcel.id } }" class="sfActive" title="Отправление">{{parcel.add_date| msk_date}}</router-link></td>
                    <td><router-link :to="{ name: 'parcels_edit', params: { id: parcel.id } }" class="sfActive" title="Отправление">{{parcel.id}}</router-link></td>
                    <td><router-link :to="{ name: 'parcels_edit', params: { id: parcel.id } }" class="sfActive" title="Отправление">{{refBarcode(parcel)}}</router-link></td>
                    <td>{{parcel.status}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/Http'
import { mapMutations, mapGetters } from 'vuex'
import Header from '../../components/SiteHeader'
import Menu from '../../components/SiteMenu'
export default {
  name: 'parcels',
  components: {
    Header,
    Menu
  },
  data() {
    return {
      myData: {},
    }
  },
  computed: {
    ...mapGetters(['ROLE_IS']),
  },
  methods: {
    ...mapMutations(['CLEAR_USER']),
    loadParcelsList: function () {
      Http.getNewParcelsList()
        .then(resp => {
          this.myData = resp.data;
        })
        .catch(() => console.error('Loading error'))
    },
    labelImageUrl: function(parcel){
      return `${process.env.VUE_APP_ROOT_API}${parcel.label_image}`
    },
    refBarcode: function (parcel) {
      if(parcel.ref_barcode != null)
        return `${parcel.ref_barcode}`;
      else
        return "";
    }
  },
  created() {
    this.loadParcelsList()
  }
}
</script>
